import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import CustomRouter from './router/CustomRouter';
import history from './router/history';
import { Provider } from 'react-redux';
import CustomLoader from './components/UI/CustomLoader/CustomLoader';
import App from './pages/App';
import { store } from './state/store/store';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <CustomRouter history={history}>
      <Provider store={store}>
        <Suspense fallback={<CustomLoader />}>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </Suspense>
      </Provider>
    </CustomRouter>
  </React.StrictMode>,
);
