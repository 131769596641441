import React from "react";
import { Grid, GridProps } from "@mui/material";

type Props = GridProps;

export const CustomFormContainerItem = (props: Props) => {
  const { style, ...rest } = props;

  return (
    <Grid item style={{ display: "flex", ...style }} {...rest}>
      {props.children}
    </Grid>
  );
};
