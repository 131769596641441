import ApiService from '../ApiService/ApiService';
import { ApiUrls } from '../ApiService/ApiUrls';

import { SignInModel } from '../../components/Account/models/SignInModel';
import { SignInResponseModel } from '../../components/Account/models/SignInResponseModel';

class AuthenticationService {
  async signIn(data: SignInModel) {
    const url = ApiUrls.Auth + 'login';
    const response = await ApiService.post<SignInResponseModel>(
      url,
      { username: data.username, password: data.password },
      {
        fallbackErrorMessage: 'Nie udało sie zalogować',
      },
    );

    return response.data;
  }

  async refreshToken(data: string) {
    const url = ApiUrls.Auth + 'refresh';
    const response = await ApiService.put<SignInResponseModel>(
      url,
      { refreshToken: data },
      {
        fallbackErrorMessage: 'account.sessionExpired',
      },
    );

    return response.data;
  }
}

export const authenticationService = new AuthenticationService();
