import produce from 'immer';
import { ProductsState } from './productsReducer';
import { Action, Actions } from 'src/state/actions/actions';
import { OrderList, ProductSortOption } from 'src/models/enums';

export const setFiltersHandler = (
  state: ProductsState,
  action: Action<typeof Actions.common.products.setFilters>,
) => {
  return produce(state, (draft) => {
    draft.filters = action.payload;
  });
};

export const setProductBrandsHandler = (
  state: ProductsState,
  action: Action<typeof Actions.common.products.setProductBrands>,
) => {
  return produce(state, (draft) => {
    draft.brands = action.payload;
  });
};

export const setSelectedBrandsHandler = (
  state: ProductsState,
  action: Action<typeof Actions.common.products.setSelectedBrands>,
) => {
  return produce(state, (draft) => {
    draft.selectedBrands = action.payload;
  });
};

export const clearFiltersHandler = (state: ProductsState) => {
  return produce(state, (draft) => {
    draft.filters = {
      CategoryId: undefined,
      //OrderList: OrderList.Asc,
      SortBy: ProductSortOption.Name,
      Name: '',
    };
  });
};

export const setSearchLockHandler = (
  state: ProductsState,
  action: Action<typeof Actions.common.products.setSearchLock>,
) => {
  return produce(state, (draft) => {
    draft.searchLock = action.payload;
  });
};

export const setLastPageHandler = (
  state: ProductsState,
  action: Action<typeof Actions.common.products.setLastPage>,
) => {
  return produce(state, (draft) => {
    draft.lastPage = action.payload;
  });
};
