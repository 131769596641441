import React, { Suspense, ReactNode } from 'react';
import CustomLoader from '../components/UI/CustomLoader/CustomLoader';

type Props = {
  component: ReactNode;
  fallback?: ReactNode;
};

const SuspenseComponent = (props: Props) => {
  const { fallback = <CustomLoader />, component } = props;

  return <Suspense fallback={fallback}>{component}</Suspense>;
};

export default SuspenseComponent;
