export enum Role {
    Unauthorized = 'u',
}
export enum OrderList {
    Asc = 'asc',
    Desc = 'desc',
}
export enum ProductSortOption {
    Name = 'nazwa',
}

export enum ArticleBasePricePerUnitOption {
    kg = 'kg',
    L = 'L',
    szt = 'szt'
}