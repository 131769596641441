import { handleActions } from 'redux-actions';
import { Actions } from 'src/state/actions/actions';
import { CustomTableState } from 'src/components/UI/CustomTable/components/props';
import {
  changePageHandler,
  loadDataHandler,
  changeSizeHandler,
  setResultHandler,
  loadMoreHandler,
} from 'src/components/UI/CustomTable/state/customTableActionsHandler';
import {
  clearFiltersHandler,
  setFiltersHandler,
  setLastPageHandler,
  setProductBrandsHandler,
  setSearchLockHandler,
  setSelectedBrandsHandler,
} from './productsActionHandlers';
import { ProductSearchQuery } from 'src/models/common/products/ProductSearchQuery';
import { ProductModel } from 'src/models/common/products/ProductModel';
import { ProductSortOption } from 'src/models/enums';
import { BrandModel } from 'src/models/common/BrandModel';

export type ProductsState = CustomTableState<ProductModel> &
  Required<Pick<CustomTableState<ProductModel>, 'pagination'>> & {
    filters: ProductSearchQuery;
    searchLock: boolean;
    lastPage: number | null;
    brands: BrandModel[];
    selectedBrands: string[];
  };

const initialState: ProductsState = {
  pagination: {
    size: 10,
    count: 0,
    pageCount: 0,
    currentPage: 1,
  },
  records: [],
  isLoading: true,
  filters: {
    CategoryId: undefined,
    OrderList: 1,
    SortBy: ProductSortOption.Name,
    Name: '',
    Brands: '',
  },
  brands: [],
  selectedBrands: [],
  searchLock: true,
  lastPage: null,
};

export default handleActions<ProductsState, any>(
  {
    [Actions.common.products.loadData]: loadDataHandler,
    [Actions.common.products.setResult]: setResultHandler,
    [Actions.common.products.loadMore]: loadMoreHandler,
    [Actions.common.products.changePage]: changePageHandler,
    [Actions.common.products.changeSize]: changeSizeHandler,
    [Actions.common.products.setFilters]: setFiltersHandler,
    [Actions.common.products.setSelectedBrands]: setSelectedBrandsHandler,
    [Actions.common.products.clearFilters]: clearFiltersHandler,
    [Actions.common.products.setProductBrands]: setProductBrandsHandler,
    [Actions.common.products.setSearchLock]: setSearchLockHandler,
    [Actions.common.products.setLastPage]: setLastPageHandler,
  },
  initialState,
);
