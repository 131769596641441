import { Components } from '@mui/material';
import { breakpoints, getMediaBreakpoints } from '../Breakpoints';
import { Color } from '../Colors';
import { Font } from '../Fonts';

export const muiAppBar: Components = {
  MuiAppBar: {
    styleOverrides: {
      root: {
        fontFamily: `${Font.Montserrat} !important`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
        [getMediaBreakpoints(breakpoints.values?.md)]: {
          position: 'sticky',
        },
        minHeight: 80,
        [getMediaBreakpoints(breakpoints.values?.md)]: {
          minHeight: 63,
        },
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.16)',
      },
      colorPrimary: {
        backgroundColor: Color.White,
      },
    },
  },
};
