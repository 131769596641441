import React, { PropsWithChildren } from 'react';
import { SnackbarProvider } from 'notistack';

type Props = Record<never, never>;

export const StyledSnackbarProvider = (props: PropsWithChildren<Props>) => {
  return (
    <SnackbarProvider
      classes={{
        variantSuccess: 'success',
      }}>
      {props.children}
    </SnackbarProvider>
  );
};
