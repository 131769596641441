import { createApplicationAction } from '../actionCreators/actionCreators';

export const routingActionsCreator = () => {
  return {
    setLastRoute: createApplicationAction<string>('SET_LAST_ROUTE'),
    setBeforeFavourites: createApplicationAction<string>(
      'SET_BEFORE_FAVOURITES',
    ),
  };
};
