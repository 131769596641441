import React from "react";
import MuiThemeProvider from "../styles/MuiThemeProvider/MuiThemeProvider";
import Router from "../router/Router";
import CustomLoader from "../components/UI/CustomLoader/CustomLoader";
import { PersistGate } from "redux-persist/es/integration/react";

import { StyledSnackbarProvider } from "../components/UI/Notifier/StyledSnackbarProvider";
import AppHookComponent from "../components/Layout/AppHookComponent/AppHookComponent";
import CommonDialogs from "../components/Dialogs/CommonDialogs";
import Notifier from "../components/UI/Notifier/components/Notifier/Notifier";
import { persistor } from "../state/store/store";

function App() {
  return (
    <PersistGate loading={<CustomLoader />} persistor={persistor}>
      <MuiThemeProvider>
        <StyledSnackbarProvider>
          <AppHookComponent />
          <CommonDialogs />
          <Notifier />
          <Router />
        </StyledSnackbarProvider>
      </MuiThemeProvider>
    </PersistGate>
  );
}

export default App;
