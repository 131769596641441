import produce from 'immer';
import { SnackbarKey } from 'notistack';
import { Action, Actions } from '../../../../state/actions/actions';
import { PayloadAction } from '../../../../state/actions/actionType';
import { Notification, NotificationState } from './notificationsReducer';

export const showNotificationHandler = (
  state: NotificationState,
  action: Action<typeof Actions.application.notifications.show>,
) => {
  return produce(state, (draft) => {
    draft.queue = [...state.queue, action.payload as Notification];
  });
};

export const dismissNotificationHandler = (
  state: NotificationState,
  action: PayloadAction<typeof Actions.application.notifications.dismiss>,
) => {
  return produce(state, (draft) => {
    const updatedQueue = draft.queue;
    const index = draft.queue.findIndex(
      (notification) =>
        notification.options?.key ===
        (action.payload as unknown as SnackbarKey),
    );
    updatedQueue[index].dismissed = true;
    draft.queue = updatedQueue;
  });
};

export const removeNotificationHandler = (
  state: NotificationState,
  action: PayloadAction<typeof Actions.application.notifications.remove>,
) => {
  return produce(state, (draft) => {
    const queue = draft.queue;
    const updatedQueue = queue.filter(
      (x) => x.options?.key !== (action.payload as unknown as SnackbarKey),
    );
    draft.queue = updatedQueue;
  });
};
