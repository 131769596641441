import { handleActions } from 'redux-actions';
import { Actions } from '../actions/actions';
import {
  setBeforeFavouritesHandler,
  setLastRouteHandler,
} from './routingActionHandlers';

export type RoutingState = {
  lastRoute: string;
  beforeFavourites: string;
};

const initialState: RoutingState = {
  lastRoute: '',
  beforeFavourites: '',
};

export default handleActions<RoutingState, any>(
  {
    [Actions.application.routing.setLastRoute]: setLastRouteHandler,
    [Actions.application.routing.setBeforeFavourites]:
      setBeforeFavouritesHandler,
  },
  initialState,
);
