import React from "react";
import { ConfirmDialog } from "./ConfirmDialog/ConfirmDialog";

const CommonDialogs = () => {
  return (
    <>
      <ConfirmDialog />
    </>
  );
};

export default CommonDialogs;
