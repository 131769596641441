import React from 'react'
import { TypesCategoriesTranslateDetailsModel } from 'src/models/common/typesCategoriesTranslate/TypesCategoriesTranslateDetailsModel'
import { TypesCategoriesTranslateModel } from 'src/models/common/typesCategoriesTranslate/TypesCategoriesTranslateModel'
import { createCommonAction, createCustomTableActions } from "../../../state/actionCreators/actionCreators";
import { TypesCategoriesNewModel } from 'src/models/common/typesCategoriesTranslate/TypesCategoriesNewModel';

const createAdminTypesCategoriesTranslateAction = <TPayLoad = undefined>(name:string) =>
    createCommonAction<TPayLoad>(`TYPESCATEGORIESTRANSLATE/${name}`);
export const typesCategoriesTranslateActionCreator = () => {
    return{
        ...createCustomTableActions<TypesCategoriesTranslateModel>(createCommonAction, 'TYPESCATEGORIESTRANSLATE'),
        setSelected: createAdminTypesCategoriesTranslateAction('SET_SELECTED'),
        loadDetails: createAdminTypesCategoriesTranslateAction('LOAD_DETAILS'),
        setDetails: createAdminTypesCategoriesTranslateAction<TypesCategoriesTranslateDetailsModel>('SET_DETAILS'),
        clearDetails: createAdminTypesCategoriesTranslateAction('CLEAR_DETAILS'),
        addTypeCategoryTranslate: createAdminTypesCategoriesTranslateAction<string>('ADD_TYPECATEGORYTRANSLATE'),
        insertNewTypeTag: createAdminTypesCategoriesTranslateAction<TypesCategoriesNewModel>('INSERT_TYPE_TAG')
    };
};