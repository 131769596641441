import React, { ForwardedRef, forwardRef } from 'react';
import { Slide, SlideProps } from '@mui/material';

const SlideUp = forwardRef(function Transition(
  props: SlideProps,
  ref: ForwardedRef<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default SlideUp;
