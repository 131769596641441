import produce from 'immer';
import { Action, Actions } from '../actions/actions';
import { RoutingState } from './routingReducer';

export const setLastRouteHandler = (
  state: RoutingState,
  action: Action<typeof Actions.application.routing.setLastRoute>,
) => {
  return produce(state, (draft) => {
    draft.lastRoute = action.payload;
  });
};

export const setBeforeFavouritesHandler = (
  state: RoutingState,
  action: Action<typeof Actions.application.routing.setBeforeFavourites>,
) => {
  return produce(state, (draft) => {
    draft.beforeFavourites = action.payload;
  });
};
