import { LanguageSelectionModel } from "src/models/common/languageSelection/LanguageSelectionModel";
import { IdModel } from "src/models/common/IdModel";
import { createCommonAction, createCustomTableActions } from "../../../state/actionCreators/actionCreators";

const createAdminLanguageSelectionAction = <TPayLoad = undefined>(name:string) =>
    createCommonAction<TPayLoad>(`LANGUAGESELECTION/${name}`);
export const languageSelectionActionCreator = () => {
    return{
        ...createCustomTableActions<LanguageSelectionModel>(createCommonAction, 'LANGUAGESELECTION'),
        setSelected: createAdminLanguageSelectionAction('SET_SELECTED'),
        loadUserLanguagesData: createAdminLanguageSelectionAction ('LOAD_USERLANGUAGES'),
        setUserLanguagesData: createAdminLanguageSelectionAction<LanguageSelectionModel> ('SET_USERLANGUAGES'),
        clearUserLanguages: createAdminLanguageSelectionAction('CLEAR_USERLANGUAGES'),
        //getSelectedLanguage: createAdminLanguageSelectionAction('GET_SELECTEDLANGUAGE'),

    };
};