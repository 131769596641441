import { AxiosError, AxiosResponse } from 'axios';
import ValidationError from '../../../errors/exceptions/ValidationError';
import UnhandledError from '../../../errors/exceptions/UnhandledError';
import SessionExpiredError from '../../../errors/exceptions/SessionExpiredError';
import { store } from '../../../state/store/store';
import ApiService from '../ApiService';
import { ApiUrls } from '../ApiUrls';
import { refreshToken, signOutWithoutReload } from '../../../components/Account/state/authentication/authenticationActions';
import { authenticationService } from '../../common/AuthenticationService';
import { showError } from '../../../components/UI/Notifier/state/notificationsActions';

declare module 'axios' {
  interface AxiosRequestConfig {
    readonly fallbackErrorMessage?: string;
    _retry?: boolean;
  }
}

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((p) => {
    if (error) {
      p.reject(error);
    } else {
      p.resolve(token);
    }
  });

  failedQueue = [];
};

export const naturalErrorInterceptor = async (error: AxiosError) => {
  const { config, response } = error;
  const originalConfig = config;
  const dispatch = store.dispatch;

  const fallbackErrorMessage = config?.fallbackErrorMessage;

  if (!response) {
    throw new UnhandledError(fallbackErrorMessage);
  }

  if (response.status === 400) {
    throw new UnhandledError(fallbackErrorMessage);
  }

  if (response && response.status === 401 && !originalConfig._retry) {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          if (originalConfig.headers) {
            originalConfig.headers['Authorization'] = `Bearer ${token}`;
          }
          return ApiService.request(originalConfig);
        })
        .catch(async () => {
          await dispatch(signOutWithoutReload());
          throw new SessionExpiredError();
        });
    }

    originalConfig._retry = true;
    isRefreshing = true;

    const rt = store.getState().authentication.refresh_token;
    return new Promise((resolve, reject) => {
      authenticationService
        .refreshToken(rt ?? '')
        .then(async (res) => {
          await dispatch(refreshToken(res));
          if (originalConfig.headers) {
            originalConfig.headers[
              'Authorization'
            ] = `Bearer ${res.access_token}`;
          }
          processQueue(null, res.access_token);
          resolve(ApiService(originalConfig));
        })
        .catch((err) => {
          processQueue(err, null);
          reject(err);
          dispatch(signOutWithoutReload());
          throw new SessionExpiredError();
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  }

  return Promise.reject(error);
};

export const errorInterceptor = async (
  error: AxiosResponse<any>,
) => {
  const { config, data } = error;
  const fallbackErrorMessage = config?.fallbackErrorMessage;
  const { dispatch } = store;

  if (!data) {
    console.log('errorInterceptor', error);
    throw new UnhandledError(fallbackErrorMessage);
  }

  if (error.status === 202 && !config?.url?.startsWith(ApiUrls.Retry)) {
    if (config?.maxRetries) {
      const maxRetries = config?.maxRetries;
      let currentRetry = 1;
      while (currentRetry < maxRetries) {
        const retry = await ApiService.get<any>(
          ApiUrls.Retry + error.data,
        );
        if (retry.status === 202) {
          currentRetry++;
        } else {
          return retry;
        }

        if (maxRetries === currentRetry) {
          try {
            throw new UnhandledError('apiError.tooManyRetries');
          } catch (error: any) {
            await dispatch(showError(error.message));
          }
        }
      }
    }
  }

  if (data.Status !== 200 && data.Error) {
    switch (data.Status) {
      case 400: {
        try {
          throw new ValidationError(
            fallbackErrorMessage ?? '',
            data.Error.Details,
          );
        } catch (error: any) {
          for (const { Message, Field } of error.errors) {
            if (!Field) {
              await dispatch(showError(Message));
            }
          }
          throw new ValidationError(
            fallbackErrorMessage ?? '',
            data.Error.Details,
          );
        }
      }
      default: {
        throw new UnhandledError(fallbackErrorMessage);
      }
    }
  } else {
    return error;
  }
};
