import produce from 'immer';
import { Action, Actions } from 'src/state/actions/actions';
import { SearchState } from './searchReducer';

export const loadResultsHandler = (state: SearchState) => {
  return produce(state, (draft: SearchState) => {
    draft.isLoadingData = true;
  });
};

export const openSearchHandler = (state: SearchState) => {
  return produce(state, (draft: SearchState) => {
    draft.isSearchOpen = true;
  });
};

export const closeSearchHandler = (state: SearchState) => {
  return produce(state, (draft: SearchState) => {
    draft.isSearchOpen = false;
  });
};

export const clearResultsHandler = (state: SearchState) => {
  return produce(state, (draft: SearchState) => {
    draft.Count = 0;
    draft.Items = [];
    draft.Page = 1;
    draft.Limit = 10;
    draft.isLoadingData = true;
  });
};

export const setResultsHandler = (
  state: SearchState,
  action: Action<typeof Actions.common.search.setResults>,
) => {
  return produce(state, (draft: SearchState) => {
    draft.Count = action.payload.Count;
    draft.Items = action.payload.Items;
    draft.Page = action.payload.Page;
    draft.Limit = action.payload.Limit;
    draft.isLoadingData = false;
  });
};
