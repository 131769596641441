import { Components } from '@mui/material';
import { Color } from '../Colors';

export const muiButton: Components = {
  MuiButton: {
    styleOverrides: {
      root: {
        boxShadow: '0px 5px 8px 0px rgb(218 41 28 / 16%)',
        borderRadius: 8.45,
        fontSize: 16,
        lineHeight: 'unset',
        fontWeight: 700,
        minWidth: 60,
        minHeight: 48,
        '&.Mui-disabled': {
          backgroundColor: Color.Primary,
          color: Color.White,
          opacity: 0.5,
          '&:hover': {
            cursor: 'not-allowed',
          },
        },
      },

      sizeLarge: {
        padding: '18px 40px 19px',
      },

      sizeMedium: {
        padding: '15px 31px 16px',
      },

      sizeSmall: {
        padding: '10px 2em',
      },

      contained: {
        color: Color.White,
      },

      outlinedPrimary: {
        borderWidth: 2,
        borderColor: Color.Primary,
      },

      outlinedSecondary: {
        borderColor: Color.Gray2,
        background: Color.White,
      },

      outlined: {
        '&.Mui-disabled': {
          backgroundColor: Color.White,
          color: Color.Gray2,
          borderWidth: 2,
        },
        '&:hover': {
          borderWidth: 2,
          boxShadow: '0px 5px 8px 0px rgb(218 41 28 / 16%)',
        },
      },

      outlinedSizeLarge: {
        padding: '17px 40px 18px',
      },

      outlinedSizeMedium: {
        padding: '14px 31px 15px',
      },

      text: {
        minWidth: '1px',
        minHeight: 'unset',
        boxShadow: 'none !important',
        padding: '0 !important',
        fontSize: 16,
        fontWeight: 500,
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'transparent',
          textDecoration: 'underline',
        },
      },
    },
  },
};
