import { handleActions } from 'redux-actions';
import { MagCategoriesDetailsModel } from 'src/models/common/magCategoriesTranslate/MagCategoriesDetailsModel';
import { MagCategoriesTranslateModel } from 'src/models/common/magCategoriesTranslate/MagCategoriesTranslateModel';
import { Actions } from 'src/state/actions/actions';
import { CustomTableState } from '../../UI/CustomTable/components/props';
import { clearDetailsHandler, loadDetailsHandler } from './MagCategoriesTranslateActionHandlers';
import { changePageHandler, loadDataHandler, setResultHandler } from '../../UI/CustomTable/state/customTableActionsHandler';
import { setDetailsHandler } from './MagCategoriesTranslateActionHandlers';

export type magCategoriesTranslateState = CustomTableState<MagCategoriesTranslateModel> &
Required<Pick<CustomTableState<MagCategoriesTranslateModel>, 'pagination'>> & {
    details: MagCategoriesDetailsModel | null;
    isLoadingPage:boolean;
};
const initialState: magCategoriesTranslateState = {
    pagination:{
        size:10,
        count: 0,
        pageCount: 0,
        currentPage: 1,
    },
    records: [],
    details: null,
    isLoading: true,
    isLoadingPage: true
}

export default handleActions<magCategoriesTranslateState, any>(
    {
        [Actions.common.magCategoriesTranslate.setResult]: setResultHandler,
        [Actions.common.magCategoriesTranslate.loadData]: loadDataHandler,
        [Actions.common.magCategoriesTranslate.changePage]: changePageHandler,
        [Actions.common.magCategoriesTranslate.loadDetails]: loadDetailsHandler,
        [Actions.common.magCategoriesTranslate.setDetails]: setDetailsHandler,
        [Actions.common.magCategoriesTranslate.clearDetails]: clearDetailsHandler
    },
    initialState
)