import React from 'react'

export default function UnitsTranslateActionHandlers() {
  return null
}
import produce from 'immer'
import { Action, Actions } from 'src/state/actions/actions'
import { unitsTranslateState } from './UnitsTranslateReducer'

export const loadDetailsHandler = (state: unitsTranslateState) => {

    return produce(state,(draft) => {
        draft.details=null;
        draft.isLoadingPage = true;
    });
};
export const setDetailsHandler = (
    state: unitsTranslateState,
    action: Action<typeof Actions.common.unitsTranslate.loadDetails>,
  ) => {
    return produce(state, (draft) => {
      draft.details = action.payload;
      draft.isLoadingPage = false;
    });
  };
  
  export const clearDetailsHandler = (state: unitsTranslateState) => {
    return produce(state, (draft) => {
      draft.details = null;
    });
  };