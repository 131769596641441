import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { errorMiddleware } from './middlewares/errorMiddleware';
import thunk from 'redux-thunk';
import { rootReducer } from '../reducers/rootReducer';

const migrations = {
  3: ({ _persist }: any) => {
    return { _persist };
  },
};

const persistConfig = {
  key: 'pgit',
  storage,
  version: 3,
  migrate: createMigrate(migrations),
  whitelist: ['authentication', 'languageSelection'],
};

// redux bug https://github.com/reduxjs/redux-toolkit/issues/1831#issuecomment-1007857548
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      errorMiddleware,
      thunk,
    ),
  devTools:
    process.env.NODE_ENV !== 'production'
      ? {
          stateSanitizer: (state: any) =>
            state.data ? { ...state, data: '<<LONG_BLOB>>' } : state,
        }
      : false,
});

export const persistor = persistStore(store);

export type AppStore = typeof store;
export type AppState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>;
