import { handleActions } from 'redux-actions';
import { LanguageSelectionModel } from 'src/models/common/languageSelection/LanguageSelectionModel';
import { languageSelectionService } from 'src/services/common/LanguageSelectionService';
import { Actions } from 'src/state/actions/actions';
import { CustomTableState } from '../../UI/CustomTable/components/props';
import { changePageHandler, loadDataHandler, setResultHandler } from '../../UI/CustomTable/state/customTableActionsHandler';
export type LanguageSelectionState = CustomTableState<LanguageSelectionModel> &
Required<Pick<CustomTableState<LanguageSelectionModel>, 'pagination'>> & {
    isLoadingPage:boolean;
};
const initialState: LanguageSelectionState = {
    pagination:{
        size:10,
        count: 0,
        pageCount: 1,
        currentPage: 1,
    },
    records: [],
    isLoading: true,
    isLoadingPage: true
}

export default handleActions<LanguageSelectionState, any>(
    {
        [Actions.common.languageSelection.setResult]: setResultHandler,
        [Actions.common.languageSelection.loadData]: loadDataHandler,
        [Actions.common.languageSelection.changePage]: changePageHandler,

    },
    initialState
)