import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import applicationReducer from './applicationReducer';
import dialogReducer from './dialogReducer';
import authenticationReducer from '../../components/Account/state/authentication/authenticationReducer';
import commonReducer from './commonReducer';
import languageSelectionReducer from 'src/components/LanguageSelection/state/languageSelectionReducer';

export const rootReducer = combineReducers({
  application: persistReducer(
    {
      key: 'pgit_settings',
      storage,
      version: 3,
      whitelist: ['settings', 'routing', 'languageSelection'],
    },
    applicationReducer,
  ),
  authentication: authenticationReducer,
  dialog: dialogReducer,
  common: commonReducer
});
