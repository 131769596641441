import { Grid, GridProps } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { CustomFormContainerItem } from './CustomFormContainerItem';

type Props = {
  direction?: 'column' | 'row';
  fullWidth?: boolean;
} & GridProps;

export const CustomFormContainer = (props: PropsWithChildren<Props>) => {
  const { direction = 'column', fullWidth = true, children, ...rest } = props;

  const columnSize = 12;
  const rowSize = fullWidth ? true : 'auto';
  const size = direction === 'column' ? columnSize : rowSize;

  const items = React.Children.map(children, (x: any, index: number) => {
    if (!x) {
      return null;
    }
    if (x.type === CustomFormContainerItem) {
      return x;
    }
    return (
      <CustomFormContainerItem key={`$form-${index}`} xs={size}>
        {x}
      </CustomFormContainerItem>
    );
  });

  return (
    <Grid container item spacing={2} {...rest}>
      {items}
    </Grid>
  );
};
