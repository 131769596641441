export const translateError = (error = '') => {
  switch (error) {
    case 'authentication_invalid_token':
      return 'account.invalidToken';

    case 'authentication_invalid_refresh_token':
      return 'account.invalidRefreshToken';

    case 'account_invalid_current_password':
      return 'account.currentPasswordInvalid';

    case 'common_invalid_login_or_password':
      return 'account.invalidCredentials';

    case 'common_resource_not_found':
      return 'apiError.unknownError';

    case 'common_already_exists':
      return 'apiError.alreadyExist';

    case 'common_polish_language_is_required':
      return 'apiError.polishLangRequired';

    case 'agreement_polish_language_is_required':
      return 'admin.agreementPolishLangRequired';

    case 'context_is_assigned_to_company':
      return 'admin.contextIsAssigned';

    case 'context_is_assigned_to_users':
      return 'admin.userIsAssigned';

    case 'company_exists_name':
      return 'admin.companyExists';

    case 'common_required':
      return 'apiError.noEmpty';

    case 'common_is_too_long':
      return 'apiError.tooLong';

    case 'common_invalid':
      return 'apiError.invalidField';

    case 'user_exists_user':
      return 'admin.userExists';

    case 'user_is_confirm':
      return 'account.confirmedAlready';

    case 'order_order_positions_price_are_out_of_date':
      return 'order.outdatedPrices';

    default:
      return error;
  }
};
