import { handleActions } from 'redux-actions';
import {
  SnackbarMessage,
  SnackbarKey,
  VariantType,
  SnackbarAction,
  TransitionCloseHandler,
} from 'notistack';
import {
  dismissNotificationHandler,
  removeNotificationHandler,
  showNotificationHandler,
} from './notificationsActionHandlers';
import { Actions } from '../../../../state/actions/actions';
import { Dispatch } from 'redux';

export interface NotificationState {
  queue: Notification[];
}

export interface Notification {
  message: SnackbarMessage;
  options: null | {
    key: SnackbarKey;
    variant: VariantType;
    action: SnackbarAction;
    onClose?: null | TransitionCloseHandler;
  };
  dismissed: boolean;
}

const initialState: NotificationState = {
  queue: [],
};

export default handleActions<NotificationState, Dispatch>(
  {
    [Actions.application.notifications.show]: showNotificationHandler,
    [Actions.application.notifications.dismiss]: dismissNotificationHandler,
    [Actions.application.notifications.remove]: removeNotificationHandler,
    [Actions.application.notifications.clearQueue]: () => initialState,
  },
  initialState,
);
