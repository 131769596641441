import { SystemStyleObject } from '@mui/system';

export const useConnectClasses = (
  classes: SystemStyleObject | SystemStyleObject[],
  sx: any,
) => {
  return [
    ...(Array.isArray(classes) ? classes : [classes]),
    ...(Array.isArray(sx) ? sx : [sx]),
  ];
};
