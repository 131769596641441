import { createCommonAction, createCustomTableActions } from "src/state/actionCreators/actionCreators";
import { TagArticlesSearchQuery } from "src/models/common/categoriesTranslate/TagArticlesSearchQuery";
import { TagArticleModel } from "src/models/common/categoriesTranslate/TagArticleModel";
import { SetTagArticleModel } from "src/models/common/categoriesTranslate/SetTagArticleModel";

const createAdminTagArticlesAction = <TPayLoad = undefined>(name:string) =>
    createCommonAction<TPayLoad>(`TAG_ARTICLES/${name}`);
export const tagArticlesActionCreator = () => {
    return{
        ...createCustomTableActions<TagArticleModel>(createCommonAction, 'TAG_ARTICLES'),
        setSelected: createAdminTagArticlesAction('SET_SELECTED'),        
        setFilters: createAdminTagArticlesAction<TagArticlesSearchQuery>('SET_TAG_ARTICLESFILTERS'),
        clearFilters: createAdminTagArticlesAction('CLEAR_TAG_ARTICLESFILTERS'),
        setTagId: createAdminTagArticlesAction<string>('SET_TAG_ID'),
        setArticleTag: createAdminTagArticlesAction<SetTagArticleModel>('SET_ARTICLE_TAG')
    };
};