import produce from 'immer';
import { Action, Actions } from '../../../state/actions/actions';
import { CompanyState } from './companyReducer';

export const deleteCompanyHandler = (
  state: CompanyState,
  action: Action<typeof Actions.common.company.deleteCompany>,
) => {
  return produce(state, (draft) => {
    const records = draft.records.filter((x) => x.Id !== action.payload.Id);
    draft.records = records;
  });
};

export const loadDetailsHandler = (state: CompanyState) => {
  return produce(state, (draft) => {
    draft.details = null;
    draft.isLoadingPage = true;
  });
};

export const clearDetailsHandler = (state: CompanyState) => {
  return produce(state, (draft) => {
    draft.details = null;
  });
};

export const setDetailsHandler = (
  state: CompanyState,
  action: Action<typeof Actions.common.company.loadDetails>,
) => {
  return produce(state, (draft) => {
    draft.details = action.payload;
    draft.isLoadingPage = false;
  });
};

