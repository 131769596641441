import { Palette, PaletteColor } from '@mui/material';

export enum Color {
  Primary = '#DA291C',
  Secondary = '#F78823',
  Gray = '#707070',
  Gray2 = '#76777A',
  Gray3 = '#F6F7FA',
  Gray4 = '#E7E9F1',
  Outline = '#D2D3D8',
  Auxilliary = '#FACD68',
  Success = '#41BF22',
  Success2 = '#24C336',
  Error = '#C5301A',
  Bronze = '#A35F55',
  Orange1 = '#EB7621',
  Orange2 = '#FC952D',
  LightGray = '#C3C8DD',
  VeryLightGray = '#EDEEF5',
  White = '#FFFFFF',
  PureBlack = '#000000',
  Black = '#2b2121',
  Black2 = '#2C2A29',
}

export type PaletteColors = keyof {
  [T in keyof Palette as Palette[T] extends PaletteColor
    ? T
    : never]: Palette[T];
};
