import produce from 'immer'
import { Action, Actions } from 'src/state/actions/actions'
import { magCategoriesTranslateState } from './MagCategoriesTranslateReducer'

export const loadDetailsHandler = (state: magCategoriesTranslateState) => {

    return produce(state,(draft) => {
        draft.details=null;
        draft.isLoadingPage = true;
    });
};
export const setDetailsHandler = (
    state: magCategoriesTranslateState,
    action: Action<typeof Actions.common.categoriesTranslate.loadDetails>,
  ) => {
    return produce(state, (draft) => {
      draft.details = action.payload;
      draft.isLoadingPage = false;
    });
  };
  
  export const clearDetailsHandler = (state: magCategoriesTranslateState) => {
    return produce(state, (draft) => {
      draft.details = null;
    });
  };