import produce, { Draft } from 'immer';
import { Action } from 'redux-actions';
import { PaginationResult } from '../../../../models/common/PaginationResult';
import { CustomTableState } from '../components/props';

export const changePageHandler = <
  TState extends CustomTableState<TModel>,
  TModel,
>(
  state: TState,
  action: Action<number>,
) => {
  return produce(state, (draft) => {
    if (!draft.pagination) {
      return;
    }

    draft.pagination = {
      ...draft.pagination,
      currentPage: action.payload,
    };
  });
};

export const changeSizeHandler = <
  TState extends CustomTableState<TModel>,
  TModel,
>(
  state: TState,
  action: Action<number>,
) => {
  return produce(state, (draft) => {
    if (!draft.pagination) {
      return;
    }

    draft.pagination = {
      ...draft.pagination,
      // currentPage: 0,
      size: action.payload,
    };
  });
};

export const loadMoreHandler = <
  TState extends CustomTableState<TModel>,
  TModel,
>(
  state: TState,
  action: Action<TModel[] | PaginationResult<TModel>>,
) => {
  return produce(state, (draft) => {
    if ('Items' in action.payload) {
      draft.records = [
        ...draft.records,
        ...action.payload.Items,
      ] as Draft<TModel>[];
      draft.isLoading = false;
      draft.pagination = {
        count: action.payload.Count,
        pageCount: Math.ceil(action.payload.Count / action.payload.Limit),
        size: action.payload.Limit,
        currentPage: action.payload.Page,
      };
    } else {
      draft.records = [...draft.records, ...action.payload] as Draft<TModel>[];
      draft.isLoading = false;
    }
  });
};

export const setResultHandler = <
  TState extends CustomTableState<TModel>,
  TModel,
>(
  state: TState,
  action: Action<TModel[] | PaginationResult<TModel>>,
) => {
  return produce(state, (draft) => {
    if (action.payload && 'Items' in action.payload) {
      draft.records = [...action.payload.Items] as Draft<TModel>[];
      draft.isLoading = false;
      draft.selected = [];
      draft.pagination = {
        count: action.payload.Count,
        pageCount: Math.ceil(action.payload.Count / action.payload.Limit),
        size: action.payload.Limit,
        currentPage: action.payload.Page,
      };
    } else if (action.payload) {
      draft.records = [...action.payload] as Draft<TModel>[];
      draft.isLoading = false;
    } else {
      draft.records = [] as Draft<TModel>[];
      draft.isLoading = false;
    }
  });
};

export const loadDataHandler = <
  TState extends CustomTableState<TModel>,
  TModel,
>(
  state: TState,
) => {
  return produce(state, (draft) => {
    draft.isLoading = true;

    if ('selected' in state) {
      draft.selected = [];
    }
  });
};

export const setSelectedHandler = <
  TState extends CustomTableState<TModel>,
  TModel,
>(
  state: TState,
  action: Action<TModel[]>,
) => {
  return produce(state, (draft) => {
    draft.selected = action.payload as Draft<TModel>[];
  });
};
