import React from 'react';
import { Box } from '@mui/material';
import { Color } from '../../../styles/MuiThemeProvider/Colors';

const CustomLoader = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: Color.White,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Box className="loader">
        <svg className="circular-loader" viewBox="25 25 50 50">
          <circle className="loader-path" cx="50" cy="50" r="20"></circle>
        </svg>
      </Box>
    </Box>
  );
};

export default CustomLoader;
