import { CompanyDetailsModel } from "../../../models/common/CompanyDetailsModel";
import { CompanyModel } from "../../../models/common/CompanyModel";
import { IdModel } from "../../../models/common/IdModel";
import { createCommonAction, createCustomTableActions } from "../../../state/actionCreators/actionCreators";

const createAdminCompanyAction = <TPayload = undefined>(name: string) =>
  createCommonAction<TPayload>(`COMPANY/${name}`);

export const companyActionCreator = () => {
  return {
    ...createCustomTableActions<CompanyModel>(createCommonAction, 'COMPANY'),
    deleteCompany: createAdminCompanyAction<IdModel>('DELETE_COMPANY'),
    loadDetails: createAdminCompanyAction('LOAD_DETAILS'),
    setDetails: createAdminCompanyAction<CompanyDetailsModel>('SET_DETAILS'),
    clearDetails: createAdminCompanyAction('CLEAR_DETAILS'),
    setSelectedUpdate: createAdminCompanyAction('SET_SELECTED_UPDATE'),
  };
};
