import { PaletteOptions } from '@mui/material/styles/createPalette';

import { Color } from './Colors';

export const palette: PaletteOptions = {
  primary: {
    main: Color.Primary,
  },
  secondary: {
    main: Color.Secondary,
  },
  background: {
    default: Color.VeryLightGray,
  },
  action: {
    disabledBackground: Color.Gray2,
  },
  error: {
    main: Color.Error,
  },
  success: {
    main: Color.Success,
  },
  info: {
    main: Color.Auxilliary,
  },
  text: {
    primary: Color.Black2,
    secondary: Color.Gray2,
  },
  white: {
    main: Color.White,
    contrastText: Color.Black,
  },
};

declare module '@mui/material/styles' {
  interface Palette {
    white: Palette['primary'];
  }

  interface PaletteOptions {
    white: PaletteOptions['primary'];
  }
}
