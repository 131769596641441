import { companyActionCreator } from "../../components/Company/state/companyActionsCreator";
import { languageSelectionActionCreator } from "src/components/LanguageSelection/state/languageSelectionActionCreator";
import { productsActionsCreator } from "src/components/ArticlesTranslate/state/products/productsActionsCreator";
import { searchActionsCreator } from "src/components/ArticlesTranslate/components/Search/state/searchActionsCreator"; 
import { categoriesTranslateActionCreator } from "src/components/CategoriesTranslate/state/categoriesTranslateActionCreator";
import { articlesActionCreator } from "src/components/ArticlesTranslate/state/articles/articlesActionCreator";
import { typesCategoriesTranslateActionCreator } from "src/components/TypesCategoriesTranslate/state/TypesCategoriesTranslateActionCreator";
import {unitsTranslateActionCreator} from "src/components/UnitsTranslate/state/UnitsTranslateActionCreator";
import { magCategoriesTranslateActionCreator } from "src/components/MagCategoriesTranslate/state/MagCategoriesTranslateActionCreator";
import { tagArticlesActionCreator } from "src/components/CategoriesTranslate/ArticlesTag/state/articles/tagArticlesActionCreator"
import { articleTagsActionCreator } from "src/components/ArticlesTranslate/ArticleTags/state/articleTagsActionCreator";

export const commonActionsCreator = () => {
  return {
    magCategoriesTranslate: magCategoriesTranslateActionCreator(),
    articles: articlesActionCreator(),
    products: productsActionsCreator(),
    search: searchActionsCreator(),
    company: companyActionCreator(),
    languageSelection: languageSelectionActionCreator(),
    userLanguages: languageSelectionActionCreator(),
    categoriesTranslate: categoriesTranslateActionCreator(),
    typesCategoriesTranslate: typesCategoriesTranslateActionCreator(),
    unitsTranslate: unitsTranslateActionCreator(),
    tagArticles: tagArticlesActionCreator(),
    articleTags: articleTagsActionCreator(),
  };
};
