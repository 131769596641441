import { ReactNode } from 'react';
import { createDialogAction } from '../../../../state/actionCreators/actionCreators';

export const confirmDialogActionsCreator = () => {
  return {
    openDialog: createDialogAction<{
      title: string;
      message: ReactNode;
      action: (...args: any) => any | Promise<any>;
      acceptText?: string;
      cancelText?: string;
    }>('CONFIRM_DIALOG/OPEN_DIALOG'),
    closeDialog: createDialogAction('CONFIRM_DIALOG/CLOSE_DIALOG'),
  };
};
