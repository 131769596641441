import React, { lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import CustomProgress from '../components/UI/CustomProgress/CustomProgress';
import SuspenseComponent from '../hocs/SuspenseComponent';
import { RouteTable } from '../models/RouteTable';
const SignInPage = lazy(() => import('../pages/SignInPage/SignInPage'));
const HomePage = lazy(() => import('../pages/HomePage/HomePage'));
const LanguageSelectionPage = lazy(() => import('src/pages/LanguageSelectionPage/LanguageSelectionPage'));
const ArticlesTranslatePage = lazy(() => import('src/pages/ArticlesTranslatePage/ArticlesTranslatePage'));
const CategoriesTranslatePage = lazy(() => import('src/pages/CategoriesTranslatePage/CategoriesTranslatePage'));
const CompanyDetailsPage = lazy(() => import('../pages/CompanyDetailsPage/CompanyDetailsPage'));
const CategoriesTranslateDetailsPage = lazy(() => import('../pages/CategoriesTranslateDetailsPage/CategoriesTranslateDetailsPage'))
const CategoriesTranslateNewPage = lazy(() => import('../pages/CategoriesTranslateNewPage/CategoriesTranslateNewPage'))
const ArticlesTranslateDetailsPage = lazy(() => import('src/pages/ArticlesTranslateDetailsPage/ArticlesTranslateDetailsPage'));
const ArticlesTagPage = lazy(() => import('src/pages/ArticlesTagPage/ArticlesTagPage'));
const UnitsTranslatePage = lazy(() => import('src/pages/UnitsTranslatePage/UnitsTranslatePage'))
const UnitsTranslateDetailsPage = lazy(() => import('src/pages/UnitsTranslateDetailsPage/UnitsTranslateDetailsPage'))
const ArticleTagsPage = lazy(() => import('src/pages/ArticleTagsPage/ArticleTagsPage'));
const TypesCategoriesTranslatePage = lazy(() => import('src/pages/TypesCategoriesTranslatePage/TypesCategoriesTranslatePage'))
const TypesCategoriesTranslateNewPage = lazy(() => import('src/pages/TypesCategoriesTranslateNewPage/TypesCategoriesTranslateNewPage'))
const TypesCategoriesTranslateDetailsPage = lazy(() => import('src/pages/TypesCategoriesTranslateDetailsPage/TypesCategoriesTranslateDetailsPage'))
const MagCategoriesTranslatePage = lazy(() => import('src/pages/MagCategoriesTranslatePage/MagCategoriesTranslatePage'))
const MagCategoriesTranslateDetailsPage = lazy(() => import('src/pages/MagCategoriesTranslateDetailsPage/MagCategoriesTranslateDetailsPage'))
const Router = () => {
  return (
    <Routes>
      <Route
        path={RouteTable.SignIn}
        element={<SuspenseComponent component={<SignInPage />} />}
      />
      <Route
        path={RouteTable.Home}
        element={<SuspenseComponent component={<ArticlesTranslatePage />} />}
      />
      <Route
        path={RouteTable.LanguageSelection}
        element={<SuspenseComponent component={<LanguageSelectionPage />} />}
      />
      <Route
        path={RouteTable.ArticlesTranslate}
        element={<SuspenseComponent component={<ArticlesTranslatePage />} />}
      />
      <Route
        path={RouteTable.UnitsTranslate}
        element={<SuspenseComponent component={<UnitsTranslatePage />} />}
      />
      <Route
        path={RouteTable.MagCategoriesTranslate}
        element={<SuspenseComponent component={<MagCategoriesTranslatePage />} />}
      />
      <Route
        path={RouteTable.CategoriesTranslate}
        element={<SuspenseComponent component={<CategoriesTranslatePage />} />}
      />
      <Route
        path={RouteTable.TypesCategoriesTranslate}
        element={<SuspenseComponent component={<TypesCategoriesTranslatePage />} />}
      />
      <Route
        path="*"
        element={<Navigate to={RouteTable.Home} replace={true} />}
      />
      <Route
        path={`${RouteTable.Home}/:id`}
        element={
          <SuspenseComponent 
            component={<ArticlesTranslateDetailsPage />} 
            fallback={<CustomProgress/>}
          />
        }
      />
      <Route
        path={`${RouteTable.CategoriesTranslate}/:id`}
        element={
          <SuspenseComponent 
            component={<CategoriesTranslateDetailsPage />} 
            fallback={<CustomProgress/>}
          />}
      />
      <Route
        path={`${RouteTable.CategoriesTranslate}/new`}
        element={
          <SuspenseComponent 
            component={<CategoriesTranslateNewPage />} 
            fallback={<CustomProgress/>}
          />}
      />
      <Route
        path={`${RouteTable.CategoriesTranslate}/articles/:id`}
        element={
          <SuspenseComponent 
            component={<ArticlesTagPage />} 
            fallback={<CustomProgress/>}
          />}
      />
      <Route
        path={`${RouteTable.MagCategoriesTranslate}/:id`}
        element={
          <SuspenseComponent 
            component={<MagCategoriesTranslateDetailsPage />} 
            fallback={<CustomProgress/>}
          />}
      />
      <Route
        path={`${RouteTable.ArticlesTranslate}/:id`}
        element={
          <SuspenseComponent 
            component={<ArticlesTranslateDetailsPage />} 
            fallback={<CustomProgress/>}
          />}
      />
      <Route
        path={`${RouteTable.ArticlesTranslate}/:id/tags`}
        element={
          <SuspenseComponent 
            component={<ArticleTagsPage />} 
            fallback={<CustomProgress/>}
          />}
      />
      <Route
        path={`${RouteTable.UnitsTranslate}/:id`}
        element={
          <SuspenseComponent 
            component={<UnitsTranslateDetailsPage />} 
            fallback={<CustomProgress/>}
          />}
      />
      <Route
        path={`${RouteTable.TypesCategoriesTranslate}/:id`}
        element={
          <SuspenseComponent 
            component={<TypesCategoriesTranslateDetailsPage />} 
            fallback={<CustomProgress/>}
          />}
      />
      <Route
        path={`${RouteTable.TypesCategoriesTranslate}/new`}
        element={
          <SuspenseComponent 
            component={<TypesCategoriesTranslateNewPage />} 
            fallback={<CustomProgress/>}
          />}
      />
    </Routes>
  );
};

export default Router;
