import {
  Action as ReduxAction,
  BaseAction as ReduxBaseAction,
  ActionFunction0,
  ActionFunction1,
  createAction,
} from 'redux-actions';
import { PaginationResult } from '../../models/common/PaginationResult';


type ActionCreator =
  | typeof createClientAction
  | typeof createCommonAction
  | typeof createAdminAction;

const createCustomAction = <TPayload, TEnumFix extends TPayload = TPayload>(
  prefix: string,
  name: string,
) => {
  const action = createAction<TPayload>(`SPECFOOD/${prefix}/${name}`);

  return action as unknown as (TPayload extends undefined
    ? ActionFunction0<ReduxBaseAction>
    : ActionFunction1<TEnumFix, ReduxAction<TEnumFix>>) &
    symbol;
};

export const createAuthenticationAction = <TPayload = undefined>(
  name: string,
) => createCustomAction<TPayload>('AUTHENTICATION', name);

export const createCommonAction = <TPayload = undefined>(name: string) =>
  createCustomAction<TPayload>('COMMON', name);

export const createAdminAction = <TPayload = undefined>(name: string) =>
  createCustomAction<TPayload>('ADMIN', name);


export const createClientAction = <TPayload = undefined>(name: string) =>
  createCustomAction<TPayload>('CLIENT', name);

export const createApplicationAction = <TPayload = undefined>(name: string) =>
  createCustomAction<TPayload>('APPLICATION', name);

export const createDialogAction = <TPayload = undefined>(name: string) =>
  createCustomAction<TPayload>('DIALOG', name);

export const createCustomTableActions = <TModel>(
  actionCreator: ActionCreator,
  name: string,
) => {
  return {
    loadData: actionCreator(`${name}/LOAD_DATA`),
    changePage: actionCreator<number>(`${name}/SET_PAGE`),
    changeSize: actionCreator<number>(`${name}/SET_SIZE`),
    loadMore: actionCreator<TModel[] | PaginationResult<TModel>>(
      `${name}/LOAD_MORE`,
    ),
    setResult: actionCreator<TModel[] | PaginationResult<TModel>>(
      `${name}/SET_DATA`,
    ),
    setSelected: actionCreator<TModel[]>(`${name}/SET_SELECTED`),
  };
};
