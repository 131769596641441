import { handleActions } from 'redux-actions';
import { TypesCategoriesTranslateDetailsModel } from 'src/models/common/typesCategoriesTranslate/TypesCategoriesTranslateDetailsModel';
import { TypesCategoriesTranslateModel } from 'src/models/common/typesCategoriesTranslate/TypesCategoriesTranslateModel';
import { Actions } from 'src/state/actions/actions';
import { CustomTableState } from '../../UI/CustomTable/components/props';
import { clearDetailsHandler, loadDetailsHandler } from './TypesCategoriesTranslateActionHandlers';
import { changePageHandler, loadDataHandler, setResultHandler } from '../../UI/CustomTable/state/customTableActionsHandler';
import { setDetailsHandler } from './TypesCategoriesTranslateActionHandlers';

export type typesCategoriesTranslateState = CustomTableState<TypesCategoriesTranslateModel> &
Required<Pick<CustomTableState<TypesCategoriesTranslateModel>, 'pagination'>> & {
    details: TypesCategoriesTranslateDetailsModel | null;
    isLoadingPage:boolean;
};
const initialState: typesCategoriesTranslateState = {
    pagination:{
        size:10,
        count: 0,
        pageCount: 0,
        currentPage: 1,
    },
    records: [],
    details: null,
    isLoading: true,
    isLoadingPage: true
}

export default handleActions<typesCategoriesTranslateState, any>(
    {
        [Actions.common.typesCategoriesTranslate.setResult]: setResultHandler,
        [Actions.common.typesCategoriesTranslate.loadData]: loadDataHandler,
        [Actions.common.typesCategoriesTranslate.changePage]: changePageHandler,
        [Actions.common.typesCategoriesTranslate.loadDetails]: loadDetailsHandler,
        [Actions.common.typesCategoriesTranslate.setDetails]: setDetailsHandler,
        [Actions.common.typesCategoriesTranslate.clearDetails]: clearDetailsHandler
    },
    initialState
)