import React from "react";
import { SnackbarKey, VariantType } from "notistack";
import { Notification } from "./notificationsReducer";
import { CloseNotificationButton } from "../components/CloseNotificationButton/CloseNotificationButton";
import { Actions } from "../../../../state/actions/actions";

const showNotification = (message: string, variant: VariantType) => async (dispatch: any) => {
  message =
    message.trim().endsWith(".") ||
    message.trim().endsWith("!") ||
    message.trim().endsWith("?") ||
    message.trim().length === 0
      ? message.trim()
      : message.trim() + ".";

  const notification: Notification = {
    message,
    dismissed: false,
    options: {
      key: (new Date().getTime() + Math.random()).toString(),
      variant,
      action: (key: SnackbarKey) => <CloseNotificationButton notificationKey={key} />,
    },
  };

  await dispatch(Actions.application.notifications.show(notification));
};

export const showSuccess = (message: string) => async (dispatch: any) => {
  await dispatch(showNotification(message, "success"));
};

export const showSuccessWithoutTranslation = (message: string) => async (dispatch: any) => {
  await dispatch(showNotification(message, "success"));
};

export const showInfo = (message: string) => async (dispatch: any) => {
  await dispatch(showNotification(message, "info"));
};

export const showWarning = (message: string) => async (dispatch: any) => {
  await dispatch(showNotification(message, "warning"));
};

export const showError = (message: string) => async (dispatch: any) => {
  await dispatch(showNotification(message, "error"));
};

export const showErrorWithoutTranslation = (message: string) => async (dispatch: any) => {
  await dispatch(showNotification(message, "error"));
};

export const dismissNotification = (key: SnackbarKey) => async (dispatch: any) => {
  await dispatch(Actions.application.notifications.dismiss(key));
};

export const removeNotification = (key: SnackbarKey) => async (dispatch: any) => {
  await dispatch(Actions.application.notifications.remove(key));
};

export const clearQueue = () => async (dispatch: any) => {
  await dispatch(Actions.application.notifications.clearQueue());
};
