import { handleActions } from 'redux-actions';
import { UserLanguageSelectionModel } from 'src/models/common/userLanguageSelection/UserLanguageSelectionModel';
import { Actions } from 'src/state/actions/actions';
import { CustomTableState } from '../../UI/CustomTable/components/props';
import { changePageHandler, loadDataHandler, setResultHandler } from '../../UI/CustomTable/state/customTableActionsHandler';
import { setSelectedUserLanguageHandler } from './userLanguageSelectionActionHandlers';
export type UserLanguageSelectionState = CustomTableState<UserLanguageSelectionModel> &
Required<Pick<CustomTableState<UserLanguageSelectionModel>, 'pagination'>> & {
    selectedUserLanguage: '';
    isLoadingPage:boolean;
};
const initialState: UserLanguageSelectionState = {
    pagination:{
        size:10,
        count: 0,
        pageCount: 1,
        currentPage: 1,
    },
    records: [],
    selectedUserLanguage: '',
    isLoading: true,
    isLoadingPage: true
}

export default handleActions<UserLanguageSelectionState, any>(
    {
        [Actions.application.userLanguages.setResult]: setResultHandler,
        [Actions.application.userLanguages.loadData]: loadDataHandler,
        [Actions.application.userLanguages.changePage]: changePageHandler,
        [Actions.application.userLanguages.setSelectedLanguage]: setSelectedUserLanguageHandler
    },
    initialState
)