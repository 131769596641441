import { handleActions } from 'redux-actions';
import { ArticleDetailsModel } from 'src/models/articles/ArticleDetailsModel';
import { ArticleModel } from 'src/models/articles/ArticleModel';
import { ArticlesSearchQuery } from 'src/models/articles/ArticlesSearchQuery';
import { ProductModel } from 'src/models/common/products/ProductModel';
import { Actions } from 'src/state/actions/actions';
import { CustomTableState } from '../../../UI/CustomTable/components/props';
import { changePageHandler, loadDataHandler, setResultHandler } from '../../../UI/CustomTable/state/customTableActionsHandler';
import { loadDetailsHandler, setDetailsHandler, clearDetailsHandler, setFiltersHandler, clearFiltersHandler, setArticleNewImageHandler, resetArticleNewImageHandler } from './articlesActionHandler';

export type articlesState = CustomTableState<ArticleModel> &
Required<Pick<CustomTableState<ArticleModel>, 'pagination'>> & {
    isLoadingPage:boolean;
    details: ArticleDetailsModel | null;
    newImage: string,
    filters: ArticlesSearchQuery;
};
const initialState: articlesState = {
    pagination:{
        size:10,
        count: 0,
        pageCount: 0,
        currentPage: 1,
    },
    records: [],
    newImage: '',
    details: null,
    isLoading: true,
    isLoadingPage: true,
    filters:{
        queryFiltr: '',
        sort_order: '',
        sort_by: '',
    }

}

export default handleActions<articlesState, any>(
    {
        [Actions.common.articles.setResult]: setResultHandler,
        [Actions.common.articles.loadData]: loadDataHandler,
        [Actions.common.articles.changePage]: changePageHandler,
        [Actions.common.articles.loadDetails]: loadDetailsHandler,
        [Actions.common.articles.setDetails]: setDetailsHandler,
        [Actions.common.articles.clearDetails]: clearDetailsHandler,
        [Actions.common.articles.setFilters]: setFiltersHandler,
        [Actions.common.articles.clearFilters]: clearFiltersHandler,
        [Actions.common.articles.setNewImage]: setArticleNewImageHandler,
        [Actions.common.articles.resetNewImage]: resetArticleNewImageHandler

    },
    initialState
)