import { ProductModel } from "src/models/common/products/ProductModel";
import { IdModel } from "src/models/common/IdModel";
import { createCommonAction, createCustomTableActions } from "../../../../state/actionCreators/actionCreators";
import { ArticleModel } from "src/models/articles/ArticleModel";
import { ArticleDetailsModel } from "src/models/articles/ArticleDetailsModel";
import { ArticlesSearchQuery } from "src/models/articles/ArticlesSearchQuery";

const createAdminArticlesAction = <TPayLoad = undefined>(name:string) =>
    createCommonAction<TPayLoad>(`ARTICLES/${name}`);
export const articlesActionCreator = () => {
    return{
        ...createCustomTableActions<ArticleModel>(createCommonAction, 'ARTICLES'),
        setSelected: createAdminArticlesAction('SET_SELECTED'),
        loadDetails: createAdminArticlesAction('LOAD_DETAILS'),
        setDetails: createAdminArticlesAction<ArticleModel>('SET_DETAILS'),
        clearDetails: createAdminArticlesAction('CLEAR_DETAILS'),
        setFilters: createAdminArticlesAction<ArticlesSearchQuery>('SET_ARTICLEFILTERS'),
        clearFilters: createAdminArticlesAction('CLEAR_ARTICLEFILTERS'),
        setNewImage: createAdminArticlesAction<string>('SET_ARTICLENEWIMAGE'),
        resetNewImage: createAdminArticlesAction('RESET_ARTICLENEWIMAGE'),
        addArticleTranslate: createAdminArticlesAction<string>('ADD_ARTICLETRANSLATE'),
        addArticleImage: createAdminArticlesAction('ADD_ARTICLEIMAGE'),
        setDefaultArticleImage: createAdminArticlesAction('SET_DEFAULTARTICLEIMAGE'),
        deleteArticleImage: createAdminArticlesAction('DELETE_ARTICLEIMAGE')
    };
};