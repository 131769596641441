import { SnackbarKey } from 'notistack';
import { Notification } from './notificationsReducer';
import { createApplicationAction } from '../../../../state/actionCreators/actionCreators';

export const notificationsActionsCreator = () => {
  return {
    show: createApplicationAction<Notification>('NOTIFICATIONS/SHOW'),
    dismiss: createApplicationAction<SnackbarKey>('NOTIFICATIONS/DISMISS'),
    remove: createApplicationAction<SnackbarKey>('NOTIFICATIONS/REMOVE'),
    clearQueue: createApplicationAction('NOTIFICATIONS/CLEAR_QUEUE'),
  };
};
