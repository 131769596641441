import { handleActions } from 'redux-actions';
import { Actions } from 'src/state/actions/actions';
import {
  loadResultsHandler,
  setResultsHandler,
  openSearchHandler,
  closeSearchHandler,
  clearResultsHandler,
} from './searchActionHandlers';
import { SearchResponseModel } from '../models/SearchResponseModel';

export type SearchState = SearchResponseModel & {
  isSearchOpen: boolean;
  isLoadingData: boolean;
};

const initialState: SearchState = {
  Count: 0,
  Items: [],
  Page: 1,
  Limit: 10,
  isSearchOpen: false,
  isLoadingData: true,
};

export default handleActions<SearchState, any>(
  {
    [Actions.common.search.loadResults]: loadResultsHandler,
    [Actions.common.search.setResults]: setResultsHandler,
    [Actions.common.search.openDesktopSearch]: openSearchHandler,
    [Actions.common.search.closeDesktopSearch]: closeSearchHandler,
    [Actions.common.search.clearResults]: clearResultsHandler,
  },
  initialState,
);
