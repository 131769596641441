import { handleActions } from 'redux-actions';
import { Role } from '../../../../models/enums';
import { Actions } from '../../../../state/actions/actions';
import {
  setEmailHandler,
  signInHandler,
  refreshTokenHandler,
  signOutHandler,
} from './authenticationActionHandlers';

export type AuthenticationState = {
  access_token: string | null;
  refresh_token: string | null;
  role: string[];
  claims: string[];
  checksum: string | null;
  username: string | null;
  email?: string;
  apiUrl: string | null;
};

const initialState: AuthenticationState = {
  access_token: null,
  refresh_token: null,
  role: [Role.Unauthorized],
  claims: [],
  checksum: null,
  username: null,
  apiUrl: null,
};

export default handleActions<AuthenticationState, any>(
  {
    [Actions.authentication.signIn]: signInHandler,
    [Actions.authentication.refreshToken]: refreshTokenHandler,
    [Actions.authentication.setEmail]: setEmailHandler,
    [Actions.authentication.signOut]: signOutHandler,
  },
  initialState,
);
