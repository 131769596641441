import produce from 'immer'
import { Action, Actions } from 'src/state/actions/actions'
import { UserLanguageSelectionState } from './userLanguageSelectionReducer'


export const loadSelectedUserLanguageHandler = (state: UserLanguageSelectionState ) => {
  return produce(state, (draft) => {
    draft.selectedUserLanguage=''
  })
}

export const setSelectedUserLanguageHandler = (
  state: UserLanguageSelectionState,
  action: Action<typeof Actions.common.userLanguages.loadUserLanguagesData>,
  ) => {
  return produce(state, (draft) => {
    draft.selectedUserLanguage=action.payload;
  })
}


