import produce from 'immer';
import { Role } from '../../../../models/enums';
import { Action, Actions } from '../../../../state/actions/actions';
import { AuthenticationState } from './authenticationReducer';

export const signInHandler = (
  state: AuthenticationState,
  action: Action<typeof Actions.authentication.signIn>,
) => {
  return produce(state, (draft) => {
    draft.access_token = action.payload.access_token;
    draft.refresh_token = action.payload.refresh_token;
    draft.role = action.payload.role;
    draft.claims = action.payload.claims;
    draft.apiUrl = action.payload.apiUrl;
  });
};

export const signOutHandler = (state: AuthenticationState) => {
  return produce(state, (draft) => {
    draft.access_token = null;
    draft.refresh_token = null;
    draft.role = [Role.Unauthorized];
    draft.claims = [];
    draft.checksum = null;
    draft.username = null;
  });
};


export const refreshTokenHandler = (
  state: AuthenticationState,
  action: Action<typeof Actions.authentication.refreshToken>,
) => {
  return produce(state, (draft) => {
    draft.access_token = action.payload.access_token;
    draft.refresh_token = action.payload.refresh_token;
  });
};

export const setEmailHandler = (
  state: AuthenticationState,
  action: Action<typeof Actions.authentication.setEmail>,
) => {
  return produce(state, (draft) => {
    draft.email = action.payload;
  });
};

