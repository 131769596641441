import { BreakpointsOptions } from '@mui/material';

export const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    mobile: 450,
    sm: 600,
    md: 900,
    lg: 1200,
    laptop: 1366,
    xl: 1536,
  },
};

export const getMediaBreakpoints = (values: number | undefined) => {
  if (!values) {
    throw new Error('Not exist Breakpoints');
  }
  return `@media (max-width:${values}px)`;
};

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    laptop: true;
    mobile: true;
  }
}
