import axios from 'axios';
import { authenticationInterceptor } from './interceptors/authenticationInterceptor';
import {
  errorInterceptor,
  naturalErrorInterceptor,
} from './interceptors/errorInterceptor';
declare module 'axios' {
  interface AxiosRequestConfig {
    readonly getStatusResponse?: (status: number) => void;
    maxRetries?: number;
  }
}

const apiBase = window.$$env.API_URL

const ApiService = axios.create({
  maxRetries: 6,
  baseURL: apiBase
});

ApiService.interceptors.request.use(authenticationInterceptor);

ApiService.interceptors.response.use(errorInterceptor, naturalErrorInterceptor);

export default ApiService;
