import { handleActions } from 'redux-actions';
import { UnitsTranslateDetailsModel } from 'src/models/common/unitsTranslate/UnitsTranslateDetailsModel';
import { UnitsTranslateModel } from 'src/models/common/unitsTranslate/UnitsTranslateModel';
import { Actions } from 'src/state/actions/actions';
import { CustomTableState } from '../../UI/CustomTable/components/props';
import { clearDetailsHandler, loadDetailsHandler } from './UnitsTranslateActionHandlers';
import { changePageHandler, loadDataHandler, setResultHandler } from '../../UI/CustomTable/state/customTableActionsHandler';
import { setDetailsHandler } from './UnitsTranslateActionHandlers';

export type unitsTranslateState = CustomTableState<UnitsTranslateModel> &
Required<Pick<CustomTableState<UnitsTranslateModel>, 'pagination'>> & {
    details: UnitsTranslateDetailsModel | null;
    isLoadingPage:boolean;
};
const initialState: unitsTranslateState = {
    pagination:{
        size:10,
        count: 0,
        pageCount: 0,
        currentPage: 1,
    },
    records: [],
    details: null,
    isLoading: true,
    isLoadingPage: true
}

export default handleActions<unitsTranslateState, any>(
    {
        [Actions.common.unitsTranslate.setResult]: setResultHandler,
        [Actions.common.unitsTranslate.loadData]: loadDataHandler,
        [Actions.common.unitsTranslate.changePage]: changePageHandler,
        [Actions.common.unitsTranslate.loadDetails]: loadDetailsHandler,
        [Actions.common.unitsTranslate.setDetails]: setDetailsHandler,
        [Actions.common.unitsTranslate.clearDetails]: clearDetailsHandler
    },
    initialState
)