export enum RouteTable {
    Home = '/',
    SignIn = '/sign-in',
    LanguageSelection = '/language-selection',
    ArticlesTranslate = '/articles-translate',
    CategoriesTranslate = '/tags-translate',
    TypesCategoriesTranslate = '/type-tag-translate',
    UnitsTranslate = '/units-translate',
    MagCategoriesTranslate = '/categories-translate',

}