import produce from 'immer'
import { Action, Actions } from 'src/state/actions/actions'
import { categoriesTranslateState } from './categoriesTranslateReducer'

export const loadDetailsHandler = (state: categoriesTranslateState) => {

    return produce(state,(draft) => {
        draft.details=null;
        draft.isLoadingPage = true;
    });
};
export const setDetailsHandler = (
    state: categoriesTranslateState,
    action: Action<typeof Actions.common.categoriesTranslate.loadDetails>,
  ) => {
    return produce(state, (draft) => {
      draft.details = action.payload;
      draft.isLoadingPage = false;
    });
  };
  
  export const clearDetailsHandler = (state: categoriesTranslateState) => {
    return produce(state, (draft) => {
      draft.details = null;
    });
  };

  export const setCategoryNewImageHandler = (
    state: categoriesTranslateState,
    action: Action<typeof Actions.common.categoriesTranslate.setCategoryNewImage>
  ) => {
    return produce(state, (draft) => {
      draft.newImage = action.payload
    })
  }

  export const resetCategoryNewImageHandler = (state: categoriesTranslateState) => {
    return produce(state, (draft) => {
      draft.newImage = '';
    })
  }

  // export const updateCategoryImageHandler = (
  //   state: categoriesTranslateState,
  //   action: Action<typeof Actions.common.categoriesTranslate.updateCategoryImage>
  // ) => {
  //   return produce
  // }