import { UserLanguageSelectionModel } from "src/models/common/userLanguageSelection/UserLanguageSelectionModel";
import { IdModel } from "src/models/common/IdModel";
import { createCommonAction, createCustomTableActions } from "../../../state/actionCreators/actionCreators";

const createAdminUserLanguageSelectionAction = <TPayLoad = undefined>(name:string) =>
    createCommonAction<TPayLoad>(`LANGUAGESELECTION/${name}`);
export const userLanguageSelectionActionCreator = () => {
    return{
        ...createCustomTableActions<UserLanguageSelectionModel>(createCommonAction, 'USERLANGUAGESELECTION'),
        setSelected: createAdminUserLanguageSelectionAction('SET_SELECTED'),
        loadUserLanguagesData: createAdminUserLanguageSelectionAction ('LOAD_USERLANGUAGES'),
        setUserLanguagesData: createAdminUserLanguageSelectionAction<UserLanguageSelectionModel> ('SET_USERLANGUAGES'),
        clearUserLanguages: createAdminUserLanguageSelectionAction('CLEAR_USERLANGUAGES'),
        //getSelectedUserLanguage: createAdminUserLanguageSelectionAction('GET_SELECTEDLANGUAGE'),
        setSelectedLanguage: createAdminUserLanguageSelectionAction<string>('SET_SELECTEDUSERLANGUAGE'),
        setUserLanguageSelected: createAdminUserLanguageSelectionAction<string>('SET_USERLANGUAGESELECTED'),
        unsetUserLanguageSelected: createAdminUserLanguageSelectionAction<string>('UNSET_USERLANGUAGESELECTED'),
    };
};