export default class UnhandledError extends Error {
  constructor(message?: string) {
    if (message && message.length > 0) {
      super(message);
    } else {
      super("");
    }

    this.name = "UnhandledError";
  }
}
