import React from 'react';
import { Box } from '@mui/material';
import { BoxProps } from '@mui/system';
import CustomCircularProgress from './CustomCircularProgress';

type Props = {
  minHeight?: string;
} & BoxProps;

const CustomProgress = ({ ...rest }: Props) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      {...rest}>
      <CustomCircularProgress />
    </Box>
  );
};

export default CustomProgress;
