import produce from 'immer'
import { OrderList, ProductSortOption } from 'src/models/enums'
import { Action, Actions } from 'src/state/actions/actions'
import { articlesState } from './tagArticlesReducer'


export const setFiltersHandler = (
    state: articlesState,
    action: Action<typeof Actions.common.tagArticles.setFilters>
    ) => {
        return produce(state, (draft) =>{
            draft.filters = action.payload;
        })
    }

export const clearFiltersHandler = (state: articlesState) =>{
    return produce(state, (draft) => {
        draft.filters = {
            sort_by: ProductSortOption.Name,
            sort_order: OrderList.Asc,
            queryFiltr: '',
            tylko_wybrane: 0
        }
    })
}

export const setCurrentTagIdHandler = (
    state: articlesState,
    action: Action<typeof Actions.common.tagArticles.setTagId>
    ) => {
        return produce(state, (draft) =>{
            draft.tagId = action.payload;
        })
    }