import { createCommonAction } from 'src/state/actionCreators/actionCreators';
import { SearchResponseModel } from '../models/SearchResponseModel';

const createSearchAction = <TPayload = undefined>(name: string) =>
  createCommonAction<TPayload>(`SEARCH/${name}`);

export const searchActionsCreator = () => {
  return {
    loadResults: createSearchAction('LOAD_RESULTS'),
    setResults: createSearchAction<SearchResponseModel>('SET_RESULTS'),
    clearResults: createSearchAction('CLEAR_RESULTS'),
    openDesktopSearch: createSearchAction('OPEN_DESKTOP_SEARCH'),
    closeDesktopSearch: createSearchAction('CLOSE_DESKTOP_SEARCH'),
  };
};
