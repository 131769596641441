import { AnyAction, Dispatch, Middleware } from 'redux';
import { showError } from '../../../components/UI/Notifier/state/notificationsActions';
import { translateError } from '../../../errors/translateError';
import ForbiddenError from '../../../errors/exceptions/ForbiddenError';
import UnhandledError from '../../../errors/exceptions/UnhandledError';
import State from '../../../models/State';
import NotifierMessageError from '../../../errors/exceptions/NotifierMessageError';
import SessionExpiredError from '../../../errors/exceptions/SessionExpiredError';
import { signOut } from '../../../components/Account/state/authentication/authenticationActions';

export const errorMiddleware: Middleware<any, any, Dispatch<AnyAction>> = (
  store: any,
) => {
  return (next: any) => async (action: any) => {
    try {
      return await next(action);
    } catch (error) {
      const handler = errorHandler(error);
      return await handler(store.dispatch, store.getState());
    }
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const errorHandler = (error: any) => async (dispatch: any, state: State) => {
  const errorAction = showError(translateError(error.message));

  if (
    error instanceof ForbiddenError ||
    error instanceof UnhandledError ||
    error instanceof NotifierMessageError
  ) {
    await dispatch(errorAction);

    return error;
  }

  if (error instanceof SessionExpiredError) {
    await dispatch(signOut());

    return error;
  }

  throw error;
};
