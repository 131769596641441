import { combineReducers } from '@reduxjs/toolkit';
import userLanguageSelectionReducer from 'src/components/UserLanguageSelection/state/userLanguageSelectionReducer';
import notificationsReducer from '../../components/UI/Notifier/state/notificationsReducer';
import routingReducer from '../routing/routingReducer';

const reducer = combineReducers({
  routing: routingReducer,
  notifications: notificationsReducer,
  userLanguage: userLanguageSelectionReducer,
});

export default reducer;
