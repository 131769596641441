import { Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useIsTablet } from "../../../hooks/breakpoints/useIsTablet";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import State from "../../../models/State";
import CustomDialogButtons from "../../UI/CustomDialog/CustomDialogButtons/CustomDialogButtons";
import CustomDialog from "../../UI/CustomDialog/CustomDialog";
import { closeConfirmDialog } from "./state/confirmDialogActions";

export const ConfirmDialog = () => {
  const { title, message, action, isOpen, acceptText, cancelText } = useSelector(
    (state: State) => state.dialog.confirmDialog
  );
  const dispatch = useAppDispatch();
  const isTablet = useIsTablet();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = async () => {
    await dispatch(closeConfirmDialog());
  };

  const handleConfirmClick = async () => {
    try {
      setIsSubmitting(true);
      if (typeof action !== "undefined") {
        await dispatch(action);
      }
      await dispatch(closeConfirmDialog());
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <CustomDialog
      keepMounted
      onClose={handleClose}
      open={isOpen}
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "600px !important",
        },
      }}
      title={title}
    >
      <Typography
        variant="body1"
        sx={{
          pt: 1,
          pb: 2,
          textAlign: isTablet ? "center" : "unset",
          whiteSpace: "pre-line",
        }}
      >
        {message}
      </Typography>
      <CustomDialogButtons
        isSubmitting={isSubmitting}
        handleSend={handleConfirmClick}
        handleCancel={handleClose}
        submitText={acceptText ?? "OK"}
        cancelText={cancelText ?? "Anuluj"}
      />
    </CustomDialog>
  );
};
