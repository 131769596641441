import { createAuthenticationAction } from '../../../../state/actionCreators/actionCreators';
import { SetTokenModel } from '../../models/SetTokenModel';
import { SignInResponseModel } from '../../models/SignInResponseModel';

export const authenticationActionsCreator = () => {
  return {
    signIn: createAuthenticationAction<SetTokenModel>('SIGN_IN'),
    refreshToken:
      createAuthenticationAction<SignInResponseModel>('REFRESH_TOKEN'),
    setEmail: createAuthenticationAction<string>('SET_EMAIL'),
    signOut: createAuthenticationAction('SIGN_OUT'),
  };
};
