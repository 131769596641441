import { handleActions } from 'redux-actions';
import { CompanyDetailsModel } from '../../../models/common/CompanyDetailsModel';
import { CompanyModel } from '../../../models/common/CompanyModel';
import { Actions } from '../../../state/actions/actions';
import { CustomTableState } from '../../UI/CustomTable/components/props';
import { changePageHandler, loadDataHandler, setResultHandler } from '../../UI/CustomTable/state/customTableActionsHandler';
import {
  deleteCompanyHandler,
  loadDetailsHandler,
  setDetailsHandler,
  clearDetailsHandler,
} from './companyActionHandlers';

export type CompanyState = CustomTableState<CompanyModel> &
  Required<Pick<CustomTableState<CompanyModel>, 'pagination'>> & {
    details: CompanyDetailsModel | null;
    isLoadingPage: boolean;
  };

const initialState: CompanyState = {
  pagination: {
    size: 10,
    count: 0,
    pageCount: 0,
    currentPage: 1,
  },
  records: [],
  details: null,
  isLoading: true,
  isLoadingPage: true,
};

export default handleActions<CompanyState, any>(
  {
    [Actions.common.company.setResult]: setResultHandler,
    [Actions.common.company.loadData]: loadDataHandler,
    [Actions.common.company.changePage]: changePageHandler,
    [Actions.common.company.loadDetails]: loadDetailsHandler,
    [Actions.common.company.clearDetails]: clearDetailsHandler,
    [Actions.common.company.setDetails]: setDetailsHandler,
    [Actions.common.company.deleteCompany]: deleteCompanyHandler,
  },
  initialState,
);
