import { ReactNode } from 'react';
import { handleActions } from 'redux-actions';
import { Actions } from '../../../../state/actions/actions';
import {
  closeConfirmDialogHandler,
  openConfirmDialogHandler,
} from './confirmDialogActionHandlers';

export type ConfirmDialogState = {
  title: string;
  message: ReactNode;
  action?: (...args: any) => any | Promise<any>;
  isOpen: boolean;
  acceptText?: string;
  cancelText?: string;
};

const initialState: ConfirmDialogState = {
  title: '',
  message: '',
  isOpen: false,
};

export default handleActions<ConfirmDialogState, any>(
  {
    [Actions.dialog.confirm.openDialog]: openConfirmDialogHandler,
    [Actions.dialog.confirm.closeDialog]: closeConfirmDialogHandler,
  },
  initialState,
);
