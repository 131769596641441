import { store } from '../../../state/store/store';
import { AxiosRequestConfig } from 'axios';

import { ApiUrls } from '../ApiUrls';

export const authenticationInterceptor = async (config: AxiosRequestConfig) => {
  if (
    store.getState().authentication.access_token &&
    config?.headers &&
    !config.url?.startsWith(ApiUrls.Auth + 'refresh') &&
    !config.url?.startsWith('https://upload.imagedelivery.net') &&
    store.getState().authentication.apiUrl
  ) {
    config.baseURL = store.getState().authentication.apiUrl as string;

    config.headers['Authorization'] = `Bearer ${
      store.getState().authentication.access_token
    }`;
  }

  return config;
};
