import produce from 'immer'
import { categoriesTranslateState } from 'src/components/CategoriesTranslate/state/categoriesTranslateReducer'
import { OrderList, ProductSortOption } from 'src/models/enums'
import { Action, Actions } from 'src/state/actions/actions'
import { articlesState } from './articlesReducer'

export const loadDetailsHandler = (state: articlesState) => {
  return produce(state,(draft) => {
    draft.details=null;
    draft.isLoadingPage = true;
  })
}

export const setDetailsHandler = (
    state: articlesState,
    action: Action<typeof Actions.common.articles.loadDetails>,
) => {
    return produce(state,(draft) => {
        draft.details = action.payload;
        draft.isLoadingPage = false;
    })
}

export const clearDetailsHandler = (state: categoriesTranslateState) => {
    return produce(state, (draft) => {
        draft.details = null;
    })
}

export const setFiltersHandler = (
    state: articlesState,
    action: Action<typeof Actions.common.articles.setFilters>
    ) => {
        return produce(state, (draft) =>{
            draft.filters = action.payload;
        })
    }
export const clearFiltersHandler = (state: articlesState) =>{
    return produce(state, (draft) => {
        draft.filters = {
            sort_by: ProductSortOption.Name,
            sort_order: OrderList.Asc,
            queryFiltr: ''
        }
    })
}

export const setArticleNewImageHandler = (
    state: articlesState,
    action: Action<typeof Actions.common.articles.setNewImage>
    ) => {
        return produce(state, (draft) => {
            draft.newImage = action.payload
        })
    }

export const resetArticleNewImageHandler = (
    state: articlesState
    ) => {
        return produce(state, (draft) => {
            draft.newImage = ''
        })
    }
