export default class ValidationError extends Error {
  errors: Pick<ValidationErrorItem, 'Field' | 'Message'>[];

  constructor(
    message: string,
    errors: Pick<ValidationErrorItem, 'Field' | 'Message'>[],
  ) {
    super(message);
    this.name = 'ValidationError';
    this.errors = errors;
  }
}

export type ValidationErrorItem = {
  Field: string;
  Message: string;
  Id: string | null;
  Details: string | null;
};
