import { BrandModel } from 'src/models/common/BrandModel';
import { ProductModel } from 'src/models/common/products/ProductModel';
import { ProductSearchQuery } from 'src/models/common/products/ProductSearchQuery';
import {
  createCommonAction,
  createCustomTableActions,
} from 'src/state/actionCreators/actionCreators';

const createProductsAction = <TPayload = undefined>(name: string) =>
  createCommonAction<TPayload>(`PRODUCTS/${name}`);

export const productsActionsCreator = () => {
  return {
    ...createCustomTableActions<ProductModel>(createCommonAction, 'PRODUCTS'),
    setProductBrands: createProductsAction<BrandModel[]>('SET_PRODUCT_BRANDS'),
    setSelectedBrands: createProductsAction<string[]>('SET_SELECTED_BRANDS'),
    setFilters: createProductsAction<ProductSearchQuery>('SET_FILTERS'),
    clearFilters: createProductsAction('CLEAR_FILTERS'),
    setSearchLock: createProductsAction<boolean>('SET_SEARCH_LOCK'),
    setLastPage: createProductsAction<number | null>('SET_LAST_PAGE'),
  };
};
