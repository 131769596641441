import { createCommonAction, createCustomTableActions } from "src/state/actionCreators/actionCreators";
import { ArticleTagsModel } from 'src/models/articles/ArticleTagsModel';

const createAdminArticleTagsAction = <TPayLoad = undefined>(name:string) =>
    createCommonAction<TPayLoad>(`ARTICLE_TAGS/${name}`);


export const articleTagsActionCreator = () => {
    return{
        ...createCustomTableActions<ArticleTagsModel>(createCommonAction, 'ARTICLE_TAGS'),
    };
};