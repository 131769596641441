import React from 'react';
import { IconButton } from '@mui/material';
import { MdClose } from 'react-icons/md';
import { SnackbarKey } from 'notistack';
import { dismissNotification } from '../../state/notificationsActions';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { Color } from '../../../../../styles/MuiThemeProvider/Colors';

type Props = {
  notificationKey: SnackbarKey;
};

export const CloseNotificationButton = (props: Props) => {
  const { notificationKey } = props;
  const dispatch = useAppDispatch();

  return (
    <IconButton
      size="small"
      onClick={async () =>
        await dispatch(dismissNotification(notificationKey))
      }>
      <MdClose color={Color.White} />
    </IconButton>
  );
};
