import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { removeNotification } from "../../state/notificationsActions";
import { Notification } from "../../state/notificationsReducer";
import State from "../../../../../models/State";
import { useAppDispatch } from "../../../../../hooks/useAppDispatch";

let displayed: any[] = [];

const Notifier = () => {
  const dispatch = useAppDispatch();
  const queue: Notification[] = useSelector((state: State) => state.application.notifications.queue);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: any) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: any) => {
    displayed = [...displayed.filter(key => id !== key)];
  };

  useEffect(() => {
    queue.forEach(item => {
      if (item.dismissed) {
        closeSnackbar(item?.options?.key);
        return;
      }

      if (displayed.includes(item?.options?.key)) return;

      enqueueSnackbar(item.message, {
        ...item.options,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        preventDuplicate: true,
        onClose: (event, reason, myKey) => {
          if (item?.options?.onClose) {
            item.options.onClose(event, reason, myKey);
          }
        },
        onExited: (_event, myKey) => {
          dispatch(removeNotification(myKey));
          removeDisplayed(myKey);
        },
      });

      storeDisplayed(item?.options?.key);
    });
  }, [queue, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};

export default Notifier;
