import { handleActions } from 'redux-actions';
import { CategoriesDetailsModel } from 'src/models/common/categoriesTranslate/CategoriesDetailsModel';
import { CategoriesTranslateModel } from 'src/models/common/categoriesTranslate/CategoriesTranslateModel';
import { Actions } from 'src/state/actions/actions';
import { CustomTableState } from '../../UI/CustomTable/components/props';
import { clearDetailsHandler, loadDetailsHandler } from './categoriesTranslateActionHandlers';
import { changePageHandler, loadDataHandler, setResultHandler,  } from '../../UI/CustomTable/state/customTableActionsHandler';
import { setDetailsHandler,setCategoryNewImageHandler, resetCategoryNewImageHandler } from './categoriesTranslateActionHandlers';

export type categoriesTranslateState = CustomTableState<CategoriesTranslateModel> &
Required<Pick<CustomTableState<CategoriesTranslateModel>, 'pagination'>> & {
    details: CategoriesDetailsModel | null;
    newImage: string,
    isLoadingPage:boolean;
};
const initialState: categoriesTranslateState = {
    pagination:{
        size:10,
        count: 0,
        pageCount: 0,
        currentPage: 1,
    },
    records: [],
    newImage:'',
    details: null,
    isLoading: true,
    isLoadingPage: true
}

export default handleActions<categoriesTranslateState, any>(
    {
        [Actions.common.categoriesTranslate.setResult]: setResultHandler,
        [Actions.common.categoriesTranslate.loadData]: loadDataHandler,
        [Actions.common.categoriesTranslate.changePage]: changePageHandler,
        [Actions.common.categoriesTranslate.loadDetails]: loadDetailsHandler,
        [Actions.common.categoriesTranslate.setDetails]: setDetailsHandler,
        [Actions.common.categoriesTranslate.clearDetails]: clearDetailsHandler,
        [Actions.common.categoriesTranslate.setCategoryNewImage]: setCategoryNewImageHandler,
        [Actions.common.categoriesTranslate.resetCategoryNewImage]: resetCategoryNewImageHandler,

    },
    initialState
)