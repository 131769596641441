import { TypographyOptions } from '@mui/material/styles/createTypography';
import { CSSProperties } from 'react';
import { breakpoints, getMediaBreakpoints } from './Breakpoints';
import { Color } from './Colors';
import { Font } from './Fonts';

export const typography: TypographyOptions = {
  fontFamily: Font.Montserrat,
  h1: {
    fontSize: 48,
    color: Color.Black2,
    fontWeight: 'bold',
    letterSpacing: '0.02em',
  },
  h2: {
    fontSize: 40,
    fontWeight: 'bold',
    lineHeight: '46px',
    [getMediaBreakpoints(breakpoints.values?.md)]: {
      fontSize: 24,
    },
    color: Color.Black2,
  },
  title: {
    fontSize: 32,
    fontWeight: 'bold',
    [getMediaBreakpoints(breakpoints.values?.md)]: {
      fontSize: 24,
    },
  },
  h3: {
    fontSize: 24,
    fontWeight: 'bold',
    [getMediaBreakpoints(breakpoints.values?.sm)]: {
      fontSize: 18,
    },
    color: Color.Black2,
  },
  h4: {
    fontSize: 20,
    fontWeight: 500,
    [getMediaBreakpoints(breakpoints.values?.sm)]: {
      fontSize: 16,
    },
    color: Color.Black2,
  },
  bodyLarge: {
    fontSize: 18,
    fontWeight: 500,
  },
  body1: {
    fontSize: 16,
  },
  body2: {
    fontSize: 14,
    color: Color.Gray2,
  },
  bodySmall: {
    fontSize: 12,
  },
  bodyTiny: {
    fontSize: 11,
  },
  bodyNano: {
    fontSize: 10,
  },
  allVariants: {
    color: Color.Black,
  },
};

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    bodySmall: true;
    bodyLarge: true;
    bodyTiny: true;
    title: true;
    subtitle: true;
    bodyNano: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    bodySmall: CSSProperties;
    bodyLarge: CSSProperties;
    bodyTiny: CSSProperties;
    title: CSSProperties;
    subtitle: CSSProperties;
    bodyNano: CSSProperties;
  }

  interface TypographyVariantsOptions {
    bodySmall?: CSSProperties;
    bodyLarge?: CSSProperties;
    bodyTiny?: CSSProperties;
    title?: CSSProperties;
    subtitle?: CSSProperties;
    bodyNano?: CSSProperties;
  }
}
