import { combineReducers } from '@reduxjs/toolkit';
import companyReducer from '../../components/Company/state/companyReducer';
import storage from 'redux-persist/lib/storage';
import languageSelectionReducer from 'src/components/LanguageSelection/state/languageSelectionReducer';
import productsReducer from 'src/components/ArticlesTranslate/state/products/productsReducer';
import searchReducer from 'src/components/ArticlesTranslate/components/Search/state/searchReducer'; 
import categoriesTranslateReducer from 'src/components/CategoriesTranslate/state/categoriesTranslateReducer';
import articlesReducer from 'src/components/ArticlesTranslate/state/articles/articlesReducer';
import TypesCategoriesTranslateReducer from 'src/components/TypesCategoriesTranslate/state/TypesCategoriesTranslateReducer';
import { persistReducer } from 'redux-persist';
import UnitsTranslateReducer from 'src/components/UnitsTranslate/state/UnitsTranslateReducer';
import MagCategoriesTranslateReducer from 'src/components/MagCategoriesTranslate/state/MagCategoriesTranslateReducer';
import tagArticlesReducer from 'src/components/CategoriesTranslate/ArticlesTag/state/articles/tagArticlesReducer';
import articleTagsReducer from 'src/components/ArticlesTranslate/ArticleTags/state/articleTagsReducer';
export type CommonState = ReturnType<typeof reducer>;

const reducer = combineReducers({
    // articles: articlesReducer,
    articles: persistReducer(
        {
          key: 'pgit_articles',
          storage,
          version: 3,
          whitelist: ['filters', 'pagination'],
        },
        articlesReducer,
      ),
    company: companyReducer,
    products: productsReducer,
    search: searchReducer,
    languageSelection: languageSelectionReducer,
    userLanguages: languageSelectionReducer,
    categoriesTranslate: categoriesTranslateReducer,
    magCategoriesTranslate: MagCategoriesTranslateReducer,
    typesCategoriesTranslate: TypesCategoriesTranslateReducer,
    unitsTranslate: UnitsTranslateReducer,
    tagArticles: persistReducer(
      {
        key: 'pgit_articles',
        storage,
        version: 3,
        whitelist: ['filters', 'pagination'],
      },
      tagArticlesReducer,
    ),
    articleTags: persistReducer(
      {
        key: 'pgit_article_tags',
        storage,
        version: 3,
      }, 
      articleTagsReducer
    )
});

export default reducer;
