import { handleActions } from 'redux-actions';
import { Actions } from 'src/state/actions/actions';
import { CustomTableState } from '../../../../UI/CustomTable/components/props';
import { changePageHandler, loadDataHandler, setResultHandler } from '../../../../UI/CustomTable/state/customTableActionsHandler';

import { TagArticlesSearchQuery } from 'src/models/common/categoriesTranslate/TagArticlesSearchQuery';
import { TagArticleModel } from 'src/models/common/categoriesTranslate/TagArticleModel';
import { clearFiltersHandler, setFiltersHandler, setCurrentTagIdHandler } from './tagArticlesActionHandler';

export type articlesState = CustomTableState<TagArticleModel> &
Required<Pick<CustomTableState<TagArticleModel>, 'pagination'>> & {
    isLoadingPage:boolean;
    tagId: string;
    filters: TagArticlesSearchQuery;
};
const initialState: articlesState = {
    pagination:{
        size:10,
        count: 0,
        pageCount: 0,
        currentPage: 1,
    },
    records: [],
    isLoading: true,
    isLoadingPage: true,
    tagId: '0',
    filters:{
        queryFiltr: '',
        sort_order: '',
        sort_by: '',
        tylko_wybrane: 0,
    }

}

export default handleActions<articlesState, any>(
    {
        [Actions.common.tagArticles.setResult]: setResultHandler,
        [Actions.common.tagArticles.loadData]: loadDataHandler,
        [Actions.common.tagArticles.changePage]: changePageHandler,
        [Actions.common.tagArticles.setFilters]: setFiltersHandler,
        [Actions.common.tagArticles.clearFilters]: clearFiltersHandler,
        [Actions.common.tagArticles.setTagId]: setCurrentTagIdHandler

    },
    initialState
)