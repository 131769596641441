import React, { PropsWithChildren } from 'react';
import {
  Box,
  Dialog,
  DialogProps,
  Typography,
  IconButton,
} from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { useIsTablet } from '../../../hooks/breakpoints/useIsTablet';
import { Color } from '../../../styles/MuiThemeProvider/Colors';
import SlideUp from '../Animations/SlideUp';
import { MdClose } from 'react-icons/md';
import { useConnectClasses } from '../../../hooks/useConnectClasses';

export type CustomDialogProps = {
  title?: string;
  onClose: () => void | Promise<void>;
} & DialogProps;

const CustomDialog = (props: PropsWithChildren<CustomDialogProps>) => {
  const { children, title, ...rest } = props;
  const isTablet = useIsTablet();

  const styles = classes(isTablet);

  const combined = useConnectClasses(styles.dialog, props.sx);

  return (
    <Dialog fullWidth TransitionComponent={SlideUp} {...rest} sx={combined}>
      <Box sx={styles.title}>
        <Typography variant="h3">{title}</Typography>
        <IconButton
          disableRipple
          sx={styles.close}
          onClick={() => {
            rest.onClose();
          }}>
          <MdClose color={Color.Black2} size={24} />
        </IconButton>
      </Box>
      <Box sx={styles.content}>{children}</Box>
    </Dialog>
  );
};

const classes: (isTablet: boolean) => Record<string, SystemStyleObject> = (
  isTablet,
) => {
  return {
    title: {
      display: 'flex',
      position: 'relative',
      justifyContent: isTablet ? 'center' : 'unset',
      alignItems: 'center',
      textAlign: isTablet ? 'center' : 'left',
      padding: isTablet ? 1.85 : 3,
      boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 16%)',
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      height: isTablet ? '100%' : 'initial',
      alignItems: isTablet ? 'center' : 'flex-start',
      justifyContent: 'flex-start',
      p: isTablet ? 1.5 : 4,
    },

    close: {
      position: 'absolute',
      right: 15,
      height: '32px',
      width: '32px',
      padding: '5px',
      backgroundColor: Color.VeryLightGray,
    },

    dialog: {
      '& .MuiDialog-container': {
        alignItems: isTablet ? 'flex-end' : 'center',
        margin: 0,
        width: '100%',
      },
      '& .MuiPaper-root': !isTablet
        ? {
            maxWidth: '1200px',
            minWidth: '1200px',
            width: 'auto',
            margin: 0,
            borderTopLeftRadius: 8.25,
            borderTopRightRadius: 8.25,
            borderBottomLeftRadius: 8.25,
            borderBottomRightRadius: 8.25,
          }
        : {
            margin: 0,
            width: '100%',
            borderTopLeftRadius: 8.25,
            borderTopRightRadius: 8.25,
          },
    },
  };
};

export default CustomDialog;
