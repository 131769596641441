import { languageSelectionActionCreator } from "src/components/LanguageSelection/state/languageSelectionActionCreator";
import { userLanguageSelectionActionCreator } from "src/components/UserLanguageSelection/state/userLanguageSelectionActionCreator";
import { notificationsActionsCreator } from "../../components/UI/Notifier/state/notificationsActionsCreator";
import { routingActionsCreator } from '../routing/routingActionsCreator';
export const applicationActionsCreator = () => {
  return {
    notifications: notificationsActionsCreator(),
    routing: routingActionsCreator(),
    userLanguages: userLanguageSelectionActionCreator(),
  };
};
