import { combineReducers } from 'redux';
import confirmDialogReducer from '../../components/Dialogs/ConfirmDialog/state/confirmDialogReducer';

export type DialogState = ReturnType<typeof dialogReducer>;

const dialogReducer = combineReducers({
    confirmDialog: confirmDialogReducer,
});

export default dialogReducer;
