import { UnitsTranslateDetailsModel } from "src/models/common/unitsTranslate/UnitsTranslateDetailsModel";
import { UnitsTranslateModel } from "src/models/common/unitsTranslate/UnitsTranslateModel";
import { IdModel } from "src/models/common/IdModel";
import { createCommonAction, createCustomTableActions } from "../../../state/actionCreators/actionCreators";


const createAdminUnitsTranslateAction = <TPayLoad = undefined>(name:string) =>
    createCommonAction<TPayLoad>(`UNITSTRANSLATE/${name}`);
export const unitsTranslateActionCreator = () => {
    return{
        ...createCustomTableActions<UnitsTranslateModel>(createCommonAction, 'UNITSTRANSLATE'),
        setSelected: createAdminUnitsTranslateAction('SET_SELECTED'),
        loadDetails: createAdminUnitsTranslateAction('LOAD_DETAILS'),
        setDetails: createAdminUnitsTranslateAction<UnitsTranslateDetailsModel>('SET_DETAILS'),
        clearDetails: createAdminUnitsTranslateAction('CLEAR_DETAILS'),
        addUnitTranslate: createAdminUnitsTranslateAction<string>('ADD_UNITTRANSLATE')
    };
};