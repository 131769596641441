import { MagCategoriesDetailsModel } from "src/models/common/magCategoriesTranslate/MagCategoriesDetailsModel";
import { MagCategoriesTranslateModel } from "src/models/common/magCategoriesTranslate/MagCategoriesTranslateModel";
import { IdModel } from "src/models/common/IdModel";
import { createCommonAction, createCustomTableActions } from "../../../state/actionCreators/actionCreators";

const createAdminMagCategoriesTranslateAction = <TPayLoad = undefined>(name:string) =>
    createCommonAction<TPayLoad>(`MAGCATEGORIESTRANSLATE/${name}`);
export const magCategoriesTranslateActionCreator = () => {
    return{
        ...createCustomTableActions<MagCategoriesTranslateModel>(createCommonAction, 'MAGCATEGORIESTRANSLATE'),
        setSelected: createAdminMagCategoriesTranslateAction('SET_SELECTED'),
        loadDetails: createAdminMagCategoriesTranslateAction('LOAD_DETAILS'),
        setDetails: createAdminMagCategoriesTranslateAction<MagCategoriesDetailsModel>('SET_DETAILS'),
        clearDetails: createAdminMagCategoriesTranslateAction('CLEAR_DETAILS'),
        addMagCategoryTranslate: createAdminMagCategoriesTranslateAction<string>('ADD_MAGCATEGORYTRANSLATE')
    };
};