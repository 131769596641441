import { CategoriesDetailsModel } from "src/models/common/categoriesTranslate/CategoriesDetailsModel";
import { CategoriesTranslateModel } from "src/models/common/categoriesTranslate/CategoriesTranslateModel";
import { CategoryImageModel } from "src/models/common/categoriesTranslate/CategoryImageModel";
import { IdModel } from "src/models/common/IdModel";
import { createCommonAction, createCustomTableActions } from "../../../state/actionCreators/actionCreators";
import { CategoriesNewModel } from "src/models/common/categoriesTranslate/CategoriesNewModel";

const createAdminCategoriesTranslateAction = <TPayLoad = undefined>(name:string) =>
    createCommonAction<TPayLoad>(`CATEGORIESTRANSLATE/${name}`);
export const categoriesTranslateActionCreator = () => {
    return{
        ...createCustomTableActions<CategoriesTranslateModel>(createCommonAction, 'CATEGORIESTRANSLATE'),
        setSelected: createAdminCategoriesTranslateAction('SET_SELECTED'),
        loadDetails: createAdminCategoriesTranslateAction('LOAD_DETAILS'),
        setDetails: createAdminCategoriesTranslateAction<CategoriesDetailsModel>('SET_DETAILS'),
        clearDetails: createAdminCategoriesTranslateAction('CLEAR_DETAILS'),
        setCategoryNewImage: createAdminCategoriesTranslateAction<string>('SET_CATEGORYIMAGE'),
        resetCategoryNewImage: createAdminCategoriesTranslateAction('RESET_CATEGORYIMAGE'),
        updateCategoryImage: createAdminCategoriesTranslateAction('UPDATE_CATEGORYIMAGE'),
        updateCategoryTranslate: createAdminCategoriesTranslateAction<string>('UPDATE_CATEGORYTRANSLATE'),
        insertNewCategory: createAdminCategoriesTranslateAction<CategoriesNewModel>('INSERT_CATEGORY')
    };
};