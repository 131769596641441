import { Action as ReduxAction, ActionFunction1 } from 'redux-actions';
import { authenticationActionsCreator } from '../../components/Account/state/authentication/authenticationActionsCreator';
import { applicationActionsCreator } from '../actionCreators/applicationActionsCreator';
import { commonActionsCreator } from '../actionCreators/commonActionsCreator';
import { dialogsActionsCreator } from '../actionCreators/dialogsActionsCreator';

export type Action<TPayload> = ReduxAction<
  TPayload extends ActionFunction1<infer U, ReduxAction<infer U>> ? U : never
>;

export const Actions = {
  authentication: authenticationActionsCreator(),
  application: applicationActionsCreator(),
  common: commonActionsCreator(),
  dialog: dialogsActionsCreator(),
};
