import { Components } from '@mui/material';
import { muiInput } from './MuiInput';
import { muiButton } from './MuiButton';
import { muiSelect } from './MuiSelect';
import { muiAppBar } from './MuiAppBar';

export const overrides: Components = {
  ...muiInput,
  ...muiSelect,
  ...muiAppBar,
  ...muiButton,
};
