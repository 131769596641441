import produce from 'immer';
import { Action, Actions } from '../../../../state/actions/actions';
import { ConfirmDialogState } from './confirmDialogReducer';

export const openConfirmDialogHandler = (
  state: ConfirmDialogState,
  action: Action<typeof Actions.dialog.confirm.openDialog>,
) => {
  return produce(state, (draft) => {
    draft.title = action.payload.title;
    draft.message = action.payload.message;
    draft.action = action.payload.action;
    draft.acceptText = action.payload.acceptText;
    draft.cancelText = action.payload.cancelText;
    draft.isOpen = true;
  });
};

export const closeConfirmDialogHandler = (state: ConfirmDialogState) => {
  return produce(state, (draft) => {
    draft.isOpen = false;
  });
};
