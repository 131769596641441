import React from 'react';
import { useIsTablet } from '../../../../hooks/breakpoints/useIsTablet';
import { CustomFormContainer } from '../../../FormControls/CustomForm/CustomFormContainer';
import { CustomFormContainerItem } from '../../../FormControls/CustomForm/CustomFormContainerItem';
import { CustomButton } from '../../CustomButton/CustomButton';

type Props = {
  submitText: string;
  cancelText?: string;
  handleCancel: () => void | Promise<void>;
  isSubmitting?: boolean;
  handleSend?: () => void | Promise<void>;
};

const CustomDialogButtons = (props: Props) => {
  const {
    isSubmitting = false,
    submitText,
    cancelText,
    handleCancel,
    handleSend,
  } = props;
  const isTablet = useIsTablet();

  const handleClick = () => {
    if (handleSend) {
      handleSend();
    }
  };

  return (
    <CustomFormContainer
      sx={{
        pt: 2,
        justifyContent: 'center',
      }}>
      <CustomFormContainerItem
        sx={{ gap: !isTablet ? 2 : 0 }}
        width="100%"
        flexDirection={isTablet ? 'column' : 'row'}>
        <CustomButton
          isFlat
          fullWidth
          variant="outlined"
          type={handleSend ? 'button' : 'submit'}
          onClick={handleClick}
          isSubmitting={isSubmitting}
          sx={{ mb: isTablet ? 2 : 0 }}>
          {submitText}
        </CustomButton>
        <CustomButton fullWidth color="primary" isFlat onClick={handleCancel}>
          {cancelText}
        </CustomButton>
      </CustomFormContainerItem>
    </CustomFormContainer>
  );
};

export default CustomDialogButtons;
