import React, { PropsWithChildren } from 'react';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';

import { breakpoints } from './Breakpoints';
import { overrides } from './Overrides/Overrides';
import { palette } from './Palette';
import { typography } from './Typography';

const theme = createTheme({
  palette: palette,
  typography: typography,
  components: overrides,
  breakpoints: breakpoints,
});

const MuiThemeProvider = (props: PropsWithChildren<unknown>) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
};

export default MuiThemeProvider;
