import history from '../../../../router/history';
import { SignInModel } from '../../models/SignInModel';
import { authenticationService } from '../../../../services/common/AuthenticationService';
import jwtDecode from 'jwt-decode';
import { DecodedToken } from '../../models/DecodedToken';
import { SetTokenModel } from '../../models/SetTokenModel';
import { SignInResponseModel } from '../../models/SignInResponseModel';
import { Actions } from '../../../../state/actions/actions';
import { RouteTable } from '../../../../models/RouteTable';

export const signIn = (data: SignInModel) => async (dispatch: any) => {
  const response = await authenticationService.signIn(data);
  let claims: string[] = [];
  const decoded = jwtDecode<DecodedToken>(response?.access_token);


  if (!Array.isArray(decoded.claims)) {
    claims.push(decoded.claims as string);
  } else {
    claims = decoded.claims as string[];
  }

  const token: SetTokenModel = {
    access_token: response.access_token,
    refresh_token: response.refresh_token,
    role: [],
    claims: [],
    apiUrl: response.apiUrl,
  };

  if (!claims) {
    token.claims = [];
  } else {
    token.claims = claims;
  }

  await dispatch(Actions.authentication.signIn(token));
};

export const refreshToken =
  (data: SignInResponseModel) => async (dispatch: any) => {
    await dispatch(Actions.authentication.refreshToken(data));
  };

export const setEmailAddress = (email: string) => async (dispatch: any) => {
  await dispatch(Actions.authentication.setEmail(email));
};

export const clearEmailAddress = () => async (dispatch: any) => {
  await dispatch(setEmailAddress(''));
};

export const signOut = () => async (dispatch: any) => {
  history.replace(RouteTable.SignIn);
  history.go(0);
  await dispatch(Actions.authentication.signOut());
};

export const signOutWithoutReload = () => async (dispatch: any) => {
  await dispatch(Actions.authentication.signOut());
};

