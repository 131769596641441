import { handleActions } from 'redux-actions';
import { Actions } from 'src/state/actions/actions';
import { CustomTableState } from 'src/components/UI/CustomTable/components/props';

import { loadDataHandler, setResultHandler } from 'src/components/UI/CustomTable/state/customTableActionsHandler';

import { ArticleTagsModel } from 'src/models/articles/ArticleTagsModel'

export type articleTagsState = CustomTableState<ArticleTagsModel> 

const initialState: articleTagsState = {
    records: [],
    isLoading: true
}

export default handleActions<articleTagsState, any>(
    {
        [Actions.common.articleTags.setResult]: setResultHandler,
        [Actions.common.articleTags.loadData]: loadDataHandler,
    },
    initialState
)
