import { ReactNode } from 'react';
import { Actions } from '../../../../state/actions/actions';

export const openConfirmDialog =
  (
    title: string,
    message: ReactNode,
    action: (...args: any) => any | Promise<any>,
    acceptText?: string,
    cancelText?: string,
  ) =>
  async (dispatch: any) => {
    await dispatch(
      Actions.dialog.confirm.openDialog({
        title,
        message,
        action,
        acceptText,
        cancelText,
      }),
    );
  };

export const closeConfirmDialog = () => async (dispatch: any) => {
  await dispatch(Actions.dialog.confirm.closeDialog());
};
