import React, { ElementType } from 'react';
import {
  Button,
  ButtonBaseProps,
  ButtonProps,
  CircularProgress,
} from '@mui/material';
import { useIsMobile } from '../../../hooks/breakpoints/useIsMobile';
import { Color } from '../../../styles/MuiThemeProvider/Colors';

export type CustomButtonProps = {
  isSubmitting?: boolean;
  fontWeight?: 'normal' | 'bold';
  isFlat?: boolean;
  component?: ElementType;
} & ButtonProps &
  ButtonBaseProps;

const CustomButtonRef = (props: CustomButtonProps, ref: any) => {
  const {
    isSubmitting = false,
    disabled,
    color = 'primary',
    variant = color === 'primary' ? 'contained' : 'outlined',
    isFlat = false,
    className,
    sx,
    ...rest
  } = props;

  return (
    <Button
      className={className}
      sx={{
        boxShadow: isFlat ? 'none' : '0px 5px 8px 0px rgb(218 41 28 / 16%)',
        ...sx,
        textTransform: 'none',
        borderRadius: 2.25,
        '& .MuiButton-endIcon': {
          position: 'absolute',
          right: '20px',
          top: '50%',
          transform: 'translateY(-50%)',
        },
      }}
      variant={variant}
      disableRipple
      color={color}
      size={useIsMobile() ? 'medium' : 'small'}
      disabled={isSubmitting || disabled}
      ref={ref}
      endIcon={
        isSubmitting && (
          <CircularProgress
            size={18}
            sx={{
              color: variant === 'outlined' ? Color.Primary : Color.White,
            }}
          />
        )
      }
      {...rest}
    />
  );
};

export const CustomButton = React.forwardRef(CustomButtonRef);
