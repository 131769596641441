// import { Color } from '../Colors';
import { Components } from '@mui/material';

export const muiSelect: Components = {
  MuiSelect: {
    styleOverrides: {
      select: {
        fontSize: 14,
        fontWeight: 500,
        minHeight: 'auto',
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
};
