import { Color } from '../Colors';
import { Components } from '@mui/material';

export const muiInput: Components = {
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: Color.Gray2,
        '& .Mui-focused': {
          color: `${Color.Gray2} !important`,
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontSize: 16,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: 12.45,
        '& fieldset': {
          transition: 'border-color .1s linear',
        },
        backgroundColor: Color.White,
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: Color.Error,
        },
        '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button':
          {
            backgroundColor: Color.Gray2,
            WebkitAppearance: 'none',
            margin: 0,
          },
        'input[type=number]': {
          MozAppearance: 'textfield',
        },
        '& .Mui-focused fieldset': {
          borderColor: Color.Primary,
        },
      },
      input: {
        minHeight: 21,
        padding: '20px 16px 19px',
        fontSize: 14,
        fontWeight: 500,
        background: Color.White,
        WebkitBoxShadow: '0 0 0 1000px white inset',
      },
      notchedOutline: {
        borderColor: Color.Outline,
      },
      multiline: {
        padding: 0,
        lineHeight: '18px',
        '& > textarea': {
          padding: '15px 16px 14px',
        },
      },
    },
  },
};
